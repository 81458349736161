/* js/components/GpsRouter.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	check_outros,
	component_subscribe,
	create_slot,
	detach,
	empty,
	group_outros,
	init,
	insert,
	safe_not_equal,
	space,
	transition_in,
	transition_out,
	update_slot
} from "svelte/internal";

import { geoLocationProvider } from "../geoLocationStore.js";
import { geoLocationState, GeoLocationState } from "../geoLocationStateStore.js";
import { onMount } from "svelte";
const get_denied_slot_changes = dirty => ({});
const get_denied_slot_context = ctx => ({});
const get_granted_slot_changes = dirty => ({});
const get_granted_slot_context = ctx => ({});
const get_processing_request_slot_changes = dirty => ({});
const get_processing_request_slot_context = ctx => ({});

// (33:0) {#if false}
function create_if_block_3(ctx) {
	let current;
	const default_slot_template = /*$$slots*/ ctx[2].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[1], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && dirty & /*$$scope*/ 2) {
					update_slot(default_slot, default_slot_template, ctx, /*$$scope*/ ctx[1], dirty, null, null);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (39:55) 
function create_if_block_2(ctx) {
	let current;
	const denied_slot_template = /*$$slots*/ ctx[2].denied;
	const denied_slot = create_slot(denied_slot_template, ctx, /*$$scope*/ ctx[1], get_denied_slot_context);

	return {
		c() {
			if (denied_slot) denied_slot.c();
		},
		m(target, anchor) {
			if (denied_slot) {
				denied_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (denied_slot) {
				if (denied_slot.p && dirty & /*$$scope*/ 2) {
					update_slot(denied_slot, denied_slot_template, ctx, /*$$scope*/ ctx[1], dirty, get_denied_slot_changes, get_denied_slot_context);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(denied_slot, local);
			current = true;
		},
		o(local) {
			transition_out(denied_slot, local);
			current = false;
		},
		d(detaching) {
			if (denied_slot) denied_slot.d(detaching);
		}
	};
}

// (37:57) 
function create_if_block_1(ctx) {
	let current;
	const granted_slot_template = /*$$slots*/ ctx[2].granted;
	const granted_slot = create_slot(granted_slot_template, ctx, /*$$scope*/ ctx[1], get_granted_slot_context);

	return {
		c() {
			if (granted_slot) granted_slot.c();
		},
		m(target, anchor) {
			if (granted_slot) {
				granted_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (granted_slot) {
				if (granted_slot.p && dirty & /*$$scope*/ 2) {
					update_slot(granted_slot, granted_slot_template, ctx, /*$$scope*/ ctx[1], dirty, get_granted_slot_changes, get_granted_slot_context);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(granted_slot, local);
			current = true;
		},
		o(local) {
			transition_out(granted_slot, local);
			current = false;
		},
		d(detaching) {
			if (granted_slot) granted_slot.d(detaching);
		}
	};
}

// (35:0) {#if $geoLocationState == GeoLocationState.PROCESSING_REQUEST}
function create_if_block(ctx) {
	let current;
	const processing_request_slot_template = /*$$slots*/ ctx[2].processing_request;
	const processing_request_slot = create_slot(processing_request_slot_template, ctx, /*$$scope*/ ctx[1], get_processing_request_slot_context);

	return {
		c() {
			if (processing_request_slot) processing_request_slot.c();
		},
		m(target, anchor) {
			if (processing_request_slot) {
				processing_request_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (processing_request_slot) {
				if (processing_request_slot.p && dirty & /*$$scope*/ 2) {
					update_slot(processing_request_slot, processing_request_slot_template, ctx, /*$$scope*/ ctx[1], dirty, get_processing_request_slot_changes, get_processing_request_slot_context);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(processing_request_slot, local);
			current = true;
		},
		o(local) {
			transition_out(processing_request_slot, local);
			current = false;
		},
		d(detaching) {
			if (processing_request_slot) processing_request_slot.d(detaching);
		}
	};
}

function create_fragment(ctx) {
	let t;
	let current_block_type_index;
	let if_block1;
	let if_block1_anchor;
	let current;
	let if_block0 = false && create_if_block_3(ctx);
	const if_block_creators = [create_if_block, create_if_block_1, create_if_block_2];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*$geoLocationState*/ ctx[0] == GeoLocationState.PROCESSING_REQUEST) return 0;
		if (/*$geoLocationState*/ ctx[0] == GeoLocationState.GRANTED) return 1;
		if (/*$geoLocationState*/ ctx[0] == GeoLocationState.DENIED) return 2;
		return -1;
	}

	if (~(current_block_type_index = select_block_type(ctx, -1))) {
		if_block1 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	}

	return {
		c() {
			if (if_block0) if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t, anchor);

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].m(target, anchor);
			}

			insert(target, if_block1_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (false) if_block0.p(ctx, dirty);
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if (~current_block_type_index) {
					if_blocks[current_block_type_index].p(ctx, dirty);
				}
			} else {
				if (if_block1) {
					group_outros();

					transition_out(if_blocks[previous_block_index], 1, 1, () => {
						if_blocks[previous_block_index] = null;
					});

					check_outros();
				}

				if (~current_block_type_index) {
					if_block1 = if_blocks[current_block_type_index];

					if (!if_block1) {
						if_block1 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
						if_block1.c();
					}

					transition_in(if_block1, 1);
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				} else {
					if_block1 = null;
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t);

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].d(detaching);
			}

			if (detaching) detach(if_block1_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $geoLocationState;
	component_subscribe($$self, geoLocationState, $$value => $$invalidate(0, $geoLocationState = $$value));

	onMount(() => {
		const unSubscribe = geoLocationProvider.subscribe(val => {
			if (val.coords && $geoLocationState != GeoLocationState.GRANTED) {
				geoLocationState.setLocationGranted();
			}

			if (val.error && val.error.code == 2 && $geoLocationState != GeoLocationState.POSITION_UNAVAILABLE_ERROR) {
				geoLocationState.setPositionUnavailableError();
			}

			if (val.error && val.error.code == 3 && $geoLocationState != GeoLocationState.TIMEOUT_ERROR) {
				geoLocationState.setTimeoutError();
			}

			if (val.error && val.error.code == 1 && $geoLocationState != GeoLocationState.DENIED) {
				geoLocationState.setLocationDenied();
			}
		});

		return () => unSubscribe();
	});

	let { $$slots = {}, $$scope } = $$props;

	$$self.$set = $$props => {
		if ("$$scope" in $$props) $$invalidate(1, $$scope = $$props.$$scope);
	};

	return [$geoLocationState, $$scope, $$slots];
}

class GpsRouter extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default GpsRouter;