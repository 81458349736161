/* js/components/UserMarker.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

import { onMount } from "svelte";
import mapboxgl from "mapbox-gl";
import { geoLocationProvider } from "../geoLocationStore.js";
import { getContext } from "svelte";
import { mapbox, key } from "../mapbox.js";
import { genevaCoords } from "../geoUtils.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-enwn0t-style";
	style.textContent = "div.svelte-enwn0t{background:#6289f5f6;border-radius:50%;border:2px solid #fff;width:12px;height:12px;z-index:9;filter:drop-shadow(0 0 0.65rem #11111155)}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			attr(div, "class", "svelte-enwn0t");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			/*div_binding*/ ctx[1](div);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			/*div_binding*/ ctx[1](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const startCoords = genevaCoords.splice();
	const { getMap } = getContext(key);
	const map = getMap();
	let markerElement;

	onMount(() => {
		const options = { element: markerElement, anchor: "center" };
		const myPositionMarker = new mapboxgl.Marker(options).setLngLat(genevaCoords).addTo(map);

		const unsubscribe = geoLocationProvider.subscribe(val => {
			if (val.coords) {
				myPositionMarker.setLngLat(val.coords);
			}
		});

		return () => unsubscribe();
	});

	function div_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			markerElement = $$value;
			$$invalidate(0, markerElement);
		});
	}

	return [markerElement, div_binding];
}

class UserMarker extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-enwn0t-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default UserMarker;