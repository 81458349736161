/* js/components/App.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Logo from "./Logo.svelte";
import DeviceFilter from "./DeviceFilter.svelte";
import DesktopDeviceContent from "./DesktopDeviceContent.svelte";
import Intro from "./Intro.svelte";
import GpsRouter from "./GpsRouter.svelte";
import WaitSpinner from "./WaitSpinner.svelte";
import MainLoop from "./MainLoop.svelte";
import NotificationDisplay from "./NotificationDisplay.svelte";
import CanvasComponent from "./CanvasComponent.svelte";
import GpsPermissionsInfoPage from "./GpsPermissionsInfoPage.svelte";
import { introStateStore } from "../introStateStore.js";

function create_else_block(ctx) {
	let gpsrouter;
	let current;

	gpsrouter = new GpsRouter({
			props: {
				$$slots: {
					default: [create_default_slot_1],
					denied: [create_denied_slot],
					granted: [create_granted_slot],
					processing_request: [create_processing_request_slot]
				},
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(gpsrouter.$$.fragment);
		},
		m(target, anchor) {
			mount_component(gpsrouter, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(gpsrouter.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(gpsrouter.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(gpsrouter, detaching);
		}
	};
}

// (44:8) {#if !$introStateStore.introCompleted}
function create_if_block(ctx) {
	let intro;
	let current;
	intro = new Intro({});

	return {
		c() {
			create_component(intro.$$.fragment);
		},
		m(target, anchor) {
			mount_component(intro, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(intro.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(intro.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(intro, detaching);
		}
	};
}

// (48:16) <div slot="processing_request">
function create_processing_request_slot(ctx) {
	let div;
	let logo;
	let t;
	let waitspinner;
	let current;
	logo = new Logo({});
	waitspinner = new WaitSpinner({});

	return {
		c() {
			div = element("div");
			create_component(logo.$$.fragment);
			t = space();
			create_component(waitspinner.$$.fragment);
			attr(div, "slot", "processing_request");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(logo, div, null);
			append(div, t);
			mount_component(waitspinner, div, null);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(logo.$$.fragment, local);
			transition_in(waitspinner.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(logo.$$.fragment, local);
			transition_out(waitspinner.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(logo);
			destroy_component(waitspinner);
		}
	};
}

// (53:16) <div class="full"  slot="granted">
function create_granted_slot(ctx) {
	let div;
	let mainloop;
	let current;
	mainloop = new MainLoop({});

	return {
		c() {
			div = element("div");
			create_component(mainloop.$$.fragment);
			attr(div, "class", "full");
			attr(div, "slot", "granted");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(mainloop, div, null);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(mainloop.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(mainloop.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(mainloop);
		}
	};
}

// (57:16) <div slot="denied">
function create_denied_slot(ctx) {
	let div;
	let gpspermissionsinfopage;
	let current;
	gpspermissionsinfopage = new GpsPermissionsInfoPage({});

	return {
		c() {
			div = element("div");
			create_component(gpspermissionsinfopage.$$.fragment);
			attr(div, "slot", "denied");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(gpspermissionsinfopage, div, null);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(gpspermissionsinfopage.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(gpspermissionsinfopage.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(gpspermissionsinfopage);
		}
	};
}

// (47:12) <GpsRouter>
function create_default_slot_1(ctx) {
	let t0;
	let t1;
	let current;

	return {
		c() {
			t0 = space();
			t1 = space();
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
		}
	};
}

// (42:4) <div class="full" slot="mobileDevice">
function create_mobileDevice_slot(ctx) {
	let div;
	let notificationdisplay;
	let t;
	let current_block_type_index;
	let if_block;
	let current;
	notificationdisplay = new NotificationDisplay({});
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (!/*$introStateStore*/ ctx[1].introCompleted) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div = element("div");
			create_component(notificationdisplay.$$.fragment);
			t = space();
			if_block.c();
			attr(div, "class", "full");
			attr(div, "slot", "mobileDevice");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(notificationdisplay, div, null);
			append(div, t);
			if_blocks[current_block_type_index].m(div, null);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index !== previous_block_index) {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				}

				transition_in(if_block, 1);
				if_block.m(div, null);
			}
		},
		i(local) {
			if (current) return;
			transition_in(notificationdisplay.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(notificationdisplay.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(notificationdisplay);
			if_blocks[current_block_type_index].d();
		}
	};
}

// (65:4) <div class="full" slot="otherDevice">
function create_otherDevice_slot(ctx) {
	let div;
	let desktopdevicecontent;
	let current;
	desktopdevicecontent = new DesktopDeviceContent({});
	desktopdevicecontent.$on("click", /*click_handler*/ ctx[2]);

	return {
		c() {
			div = element("div");
			create_component(desktopdevicecontent.$$.fragment);
			attr(div, "class", "full");
			attr(div, "slot", "otherDevice");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(desktopdevicecontent, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(desktopdevicecontent.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(desktopdevicecontent.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(desktopdevicecontent);
		}
	};
}

// (39:0) <DeviceFilter ignoreDetection={ignoreDeviceDetection}>
function create_default_slot(ctx) {
	let t;
	let current;

	return {
		c() {
			t = space();
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let devicefilter;
	let current;

	devicefilter = new DeviceFilter({
			props: {
				ignoreDetection: /*ignoreDeviceDetection*/ ctx[0],
				$$slots: {
					default: [create_default_slot],
					otherDevice: [create_otherDevice_slot],
					mobileDevice: [create_mobileDevice_slot]
				},
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(devicefilter.$$.fragment);
		},
		m(target, anchor) {
			mount_component(devicefilter, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const devicefilter_changes = {};
			if (dirty & /*ignoreDeviceDetection*/ 1) devicefilter_changes.ignoreDetection = /*ignoreDeviceDetection*/ ctx[0];

			if (dirty & /*$$scope, ignoreDeviceDetection, $introStateStore*/ 11) {
				devicefilter_changes.$$scope = { dirty, ctx };
			}

			devicefilter.$set(devicefilter_changes);
		},
		i(local) {
			if (current) return;
			transition_in(devicefilter.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(devicefilter.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(devicefilter, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $introStateStore;
	component_subscribe($$self, introStateStore, $$value => $$invalidate(1, $introStateStore = $$value));
	let ignoreDeviceDetection = false;
	const click_handler = e => $$invalidate(0, ignoreDeviceDetection = true);
	return [ignoreDeviceDetection, $introStateStore, click_handler];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;