/* js/components/mainMenu/MainMenu_feedback.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let h1;
	let br0;
	let t1;
	let h2;
	let t4;
	let br2;
	let br3;
	let t5;
	let div0;
	let t8;
	let p;
	let t9;
	let div1;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Feedback";
			br0 = element("br");
			t1 = space();
			h2 = element("h2");
			h2.innerHTML = `Nous sommes<br> à votre écoute`;
			t4 = space();
			br2 = element("br");
			br3 = element("br");
			t5 = space();
			div0 = element("div");

			div0.innerHTML = `
        Faites nous part de vos commentaires, témoignez de votre expérience!<br><br>
        Ce projet est le fruit de nombreuses heures de travail, et nous tenons à l&#39;améliorer constamment. Nous travaillons dessus chaque jour.
    `;

			t8 = space();
			p = element("p");
			p.innerHTML = `<br><br><br><br><br><br>`;
			t9 = space();
			div1 = element("div");
			div1.innerHTML = `<a href="mailto:contact@audiogenese.net"><button class="button">Contact</button></a>`;
			attr(h1, "class", "indent");
			attr(div0, "class", "text-indent");
			attr(div1, "class", "buttonWrapper");
			set_style(div1, "z-index", "3001");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, br0, anchor);
			insert(target, t1, anchor);
			insert(target, h2, anchor);
			insert(target, t4, anchor);
			insert(target, br2, anchor);
			insert(target, br3, anchor);
			insert(target, t5, anchor);
			insert(target, div0, anchor);
			insert(target, t8, anchor);
			insert(target, p, anchor);
			insert(target, t9, anchor);
			insert(target, div1, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(br0);
			if (detaching) detach(t1);
			if (detaching) detach(h2);
			if (detaching) detach(t4);
			if (detaching) detach(br2);
			if (detaching) detach(br3);
			if (detaching) detach(t5);
			if (detaching) detach(div0);
			if (detaching) detach(t8);
			if (detaching) detach(p);
			if (detaching) detach(t9);
			if (detaching) detach(div1);
		}
	};
}

class MainMenu_feedback extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default MainMenu_feedback;