/* js/components/SelectedSpotFooter.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	space,
	text,
	toggle_class
} from "svelte/internal";

import { createEventDispatcher } from "svelte";

function add_css() {
	var style = element("style");
	style.id = "svelte-1w974tt-style";
	style.textContent = ".footer.svelte-1w974tt{touch-action:none}img.svelte-1w974tt{position:absolute;right:1rem;bottom:0.25rem;width:2rem;height:2rem}.flex-it.svelte-1w974tt{display:flex;justify-content:flex-end}.text-footer.svelte-1w974tt{margin-top:0.5rem}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div3;
	let div2;
	let div0;
	let t0;
	let div1;
	let p0;
	let t1_value = (/*spot*/ ctx[1] ? /*spot*/ ctx[1].name : "") + "";
	let t1;
	let t2;
	let t3_value = (/*spot*/ ctx[1] ? /*spot*/ ctx[1].artist : "") + "";
	let t3;
	let t4;
	let p1;
	let mounted;
	let dispose;

	return {
		c() {
			div3 = element("div");
			div2 = element("div");
			div0 = element("div");
			div0.innerHTML = `<img src="maximize.svg" alt="maximize button" class="svelte-1w974tt">`;
			t0 = space();
			div1 = element("div");
			p0 = element("p");
			t1 = text(t1_value);
			t2 = text(" – ");
			t3 = text(t3_value);
			t4 = space();
			p1 = element("p");
			p1.textContent = "Geneva data by SITG (Etat de Genève), Esri community map";
			attr(div0, "class", "flex-it svelte-1w974tt");
			attr(p0, "class", "p");
			attr(p1, "class", "attribution");
			attr(div1, "class", "textCenter text-footer svelte-1w974tt");
			attr(div2, "class", "spotContent noselect");
			attr(div3, "class", "footer noselect svelte-1w974tt");
			toggle_class(div3, "footer-closed", !/*isOpen*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div2);
			append(div2, div0);
			append(div2, t0);
			append(div2, div1);
			append(div1, p0);
			append(p0, t1);
			append(p0, t2);
			append(p0, t3);
			append(div1, t4);
			append(div1, p1);
			/*div3_binding*/ ctx[4](div3);

			if (!mounted) {
				dispose = listen(div3, "click", /*onClick*/ ctx[3]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*spot*/ 2 && t1_value !== (t1_value = (/*spot*/ ctx[1] ? /*spot*/ ctx[1].name : "") + "")) set_data(t1, t1_value);
			if (dirty & /*spot*/ 2 && t3_value !== (t3_value = (/*spot*/ ctx[1] ? /*spot*/ ctx[1].artist : "") + "")) set_data(t3, t3_value);

			if (dirty & /*isOpen*/ 1) {
				toggle_class(div3, "footer-closed", !/*isOpen*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div3);
			/*div3_binding*/ ctx[4](null);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { isOpen = false } = $$props;
	let { spot } = $$props;
	let container;
	const dispatch = createEventDispatcher();

	function onClick() {
		dispatch("click", {});
	}

	function div3_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			container = $$value;
			$$invalidate(2, container);
		});
	}

	$$self.$set = $$props => {
		if ("isOpen" in $$props) $$invalidate(0, isOpen = $$props.isOpen);
		if ("spot" in $$props) $$invalidate(1, spot = $$props.spot);
	};

	return [isOpen, spot, container, onClick, div3_binding];
}

class SelectedSpotFooter extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1w974tt-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { isOpen: 0, spot: 1 });
	}
}

export default SelectedSpotFooter;