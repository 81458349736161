/* js/components/DesktopDeviceContent.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import PresentationSlideshow from "./PresentationSlideshow.svelte";
import VideoModal from "./VideoModal.svelte";
import { createEventDispatcher } from "svelte";
import { Config } from "../config.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-ssewd-style";
	style.textContent = ".center.svelte-ssewd{display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%}.root.svelte-ssewd{font-size:calc(0.5vmin + 0.8vmax)}button.svelte-ssewd{display:inline-block}";
	append(document.head, style);
}

// (58:4) {#if showVideoModal}
function create_if_block_1(ctx) {
	let videomodal;
	let current;
	videomodal = new VideoModal({});
	videomodal.$on("closeRequest", /*videoModalRequestsClosing*/ ctx[3]);

	return {
		c() {
			create_component(videomodal.$$.fragment);
		},
		m(target, anchor) {
			mount_component(videomodal, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(videomodal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(videomodal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(videomodal, detaching);
		}
	};
}

// (78:0) {#if allowDesktopContentBypass}
function create_if_block(ctx) {
	let div4;
	let div3;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			div4 = element("div");
			div3 = element("div");
			button = element("button");

			button.innerHTML = `<div class="textCourantCenter">Ignore device detection</div> 
                <div class="small-size">(Debug only)<div></div></div>`;

			attr(button, "class", "button svelte-ssewd");
			attr(div3, "class", "buttonWrapper");
			attr(div4, "class", "container");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, div3);
			append(div3, button);

			if (!mounted) {
				dispose = listen(button, "click", /*onClick*/ ctx[2]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div4);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div;
	let t0;
	let presentationslideshow;
	let t1;
	let if_block1_anchor;
	let current;
	let if_block0 = /*showVideoModal*/ ctx[0] && create_if_block_1(ctx);
	presentationslideshow = new PresentationSlideshow({});
	let if_block1 = /*allowDesktopContentBypass*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			create_component(presentationslideshow.$$.fragment);
			t1 = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
			attr(div, "class", "center root svelte-ssewd");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block0) if_block0.m(div, null);
			append(div, t0);
			mount_component(presentationslideshow, div, null);
			insert(target, t1, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, if_block1_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*showVideoModal*/ ctx[0]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*showVideoModal*/ 1) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div, t0);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*allowDesktopContentBypass*/ ctx[1]) if_block1.p(ctx, dirty);
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(presentationslideshow.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(presentationslideshow.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block0) if_block0.d();
			destroy_component(presentationslideshow);
			if (detaching) detach(t1);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(if_block1_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const dispatch = createEventDispatcher();
	const allowDesktopContentBypass = Config.allowDeviceFilterBypass;
	let showVideoModal = false;

	function onClick() {
		dispatch("click", {});
	}

	function showModal() {
		if (!showVideoModal) {
			$$invalidate(0, showVideoModal = true);
		}
	}

	function videoModalRequestsClosing() {
		$$invalidate(0, showVideoModal = false);
	}

	return [showVideoModal, allowDesktopContentBypass, onClick, videoModalRequestsClosing];
}

class DesktopDeviceContent extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-ssewd-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default DesktopDeviceContent;