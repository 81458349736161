/* js/components/ProgressBar.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-qthwr-style";
	style.textContent = ".bar.svelte-qthwr{width:100%;height:1px;margin-top:10px;margin-bottom:10px;background-color:black}.marker.svelte-qthwr{position:relative;background-color:black;width:3px;top:-10px;height:20px}.beginning.svelte-qthwr{position:relative;float:left;background-color:black;width:1px;top:-10px;height:20px}.end.svelte-qthwr{position:relative;float:right;background-color:black;width:1px;top:-10px;height:20px}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div0;
	let t0;
	let div1;
	let t1;
	let div3;
	let div2;

	return {
		c() {
			div0 = element("div");
			t0 = space();
			div1 = element("div");
			t1 = space();
			div3 = element("div");
			div2 = element("div");
			attr(div0, "class", "beginning svelte-qthwr");
			attr(div1, "class", "end svelte-qthwr");
			attr(div2, "class", "marker svelte-qthwr");
			attr(div3, "class", "bar svelte-qthwr");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			insert(target, t0, anchor);
			insert(target, div1, anchor);
			insert(target, t1, anchor);
			insert(target, div3, anchor);
			append(div3, div2);
			/*div2_binding*/ ctx[3](div2);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t0);
			if (detaching) detach(div1);
			if (detaching) detach(t1);
			if (detaching) detach(div3);
			/*div2_binding*/ ctx[3](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { duration } = $$props;
	let { currentTime } = $$props;
	let marker;

	function div2_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			marker = $$value;
			(($$invalidate(0, marker), $$invalidate(1, duration)), $$invalidate(2, currentTime));
		});
	}

	$$self.$set = $$props => {
		if ("duration" in $$props) $$invalidate(1, duration = $$props.duration);
		if ("currentTime" in $$props) $$invalidate(2, currentTime = $$props.currentTime);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*marker, duration, currentTime*/ 7) {
			$: {
				if (marker && duration && currentTime) {
					const percent = currentTime / duration * 100;
					$$invalidate(0, marker.style.left = `${percent}%`, marker);
				}
			}
		}
	};

	return [marker, duration, currentTime, div2_binding];
}

class ProgressBar extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-qthwr-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { duration: 1, currentTime: 2 });
	}
}

export default ProgressBar;