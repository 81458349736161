/* js/components/MainMenu.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	add_flush_callback,
	add_render_callback,
	append,
	attr,
	bind,
	binding_callbacks,
	check_outros,
	create_bidirectional_transition,
	create_component,
	destroy_block,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out,
	update_keyed_each
} from "svelte/internal";

import Hamburger from "./Hamburger.svelte";
import InfoView from "./InfoView.svelte";
import { slideFromBelow } from "../transitions";

function add_css() {
	var style = element("style");
	style.id = "svelte-12404vn-style";
	style.textContent = ".menu-page.svelte-12404vn{position:absolute;width:100%;height:100%;z-index:5000;background-color:white}.container.svelte-12404vn{margin:0 auto}ol.svelte-12404vn{margin-top:4rem}li.svelte-12404vn{list-style:none}button.svelte-12404vn{width:calc(100vw - 8rem);padding-top:1rem;padding-bottom:1rem;margin:0 auto;margin-bottom:2rem;margin-left:0;font-size:1.25rem;min-height:5rem;background-color:rgba(255, 255, 255, 0.9);border-radius:0px;border:2px solid #000;display:inline-block}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[7] = list[i];
	return child_ctx;
}

// (79:0) {#if menuIsOpen}
function create_if_block(ctx) {
	let div1;
	let div0;
	let ol;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let div1_transition;
	let t;
	let if_block_anchor;
	let current;
	let each_value = /*menuItems*/ ctx[2];
	const get_key = ctx => /*menuItem*/ ctx[7].id;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	let if_block = /*selectedMenuItem*/ ctx[1] && create_if_block_1(ctx);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			ol = element("ol");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(ol, "class", "svelte-12404vn");
			attr(div0, "class", "container svelte-12404vn");
			attr(div1, "class", "menu-page svelte-12404vn");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, ol);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ol, null);
			}

			insert(target, t, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*onMenuClick, menuItems*/ 12) {
				const each_value = /*menuItems*/ ctx[2];
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, ol, destroy_block, create_each_block, null, get_each_context);
			}

			if (/*selectedMenuItem*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*selectedMenuItem*/ 2) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (!div1_transition) div1_transition = create_bidirectional_transition(div1, slideFromBelow, { duration: 500 }, true);
				div1_transition.run(1);
			});

			transition_in(if_block);
			current = true;
		},
		o(local) {
			if (!div1_transition) div1_transition = create_bidirectional_transition(div1, slideFromBelow, { duration: 500 }, false);
			div1_transition.run(0);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			if (detaching && div1_transition) div1_transition.end();
			if (detaching) detach(t);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (87:16) {#each menuItems  as menuItem (menuItem.id)}
function create_each_block(key_1, ctx) {
	let li;
	let button;
	let t0_value = /*menuItem*/ ctx[7].text + "";
	let t0;
	let t1;
	let mounted;
	let dispose;

	function click_handler(...args) {
		return /*click_handler*/ ctx[6](/*menuItem*/ ctx[7], ...args);
	}

	return {
		key: key_1,
		first: null,
		c() {
			li = element("li");
			button = element("button");
			t0 = text(t0_value);
			t1 = space();
			attr(button, "class", "svelte-12404vn");
			attr(li, "class", "svelte-12404vn");
			this.first = li;
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, button);
			append(button, t0);
			append(li, t1);

			if (!mounted) {
				dispose = listen(button, "click", click_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(li);
			mounted = false;
			dispose();
		}
	};
}

// (98:4) {#if selectedMenuItem}
function create_if_block_1(ctx) {
	let infoview;
	let current;

	infoview = new InfoView({
			props: { id: /*selectedMenuItem*/ ctx[1].id }
		});

	infoview.$on("closeRequest", /*onInfoViewRequestsClosing*/ ctx[4]);

	return {
		c() {
			create_component(infoview.$$.fragment);
		},
		m(target, anchor) {
			mount_component(infoview, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const infoview_changes = {};
			if (dirty & /*selectedMenuItem*/ 2) infoview_changes.id = /*selectedMenuItem*/ ctx[1].id;
			infoview.$set(infoview_changes);
		},
		i(local) {
			if (current) return;
			transition_in(infoview.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(infoview.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(infoview, detaching);
		}
	};
}

function create_fragment(ctx) {
	let hamburger;
	let updating_open;
	let t;
	let if_block_anchor;
	let current;

	function hamburger_open_binding(value) {
		/*hamburger_open_binding*/ ctx[5].call(null, value);
	}

	let hamburger_props = {};

	if (/*menuIsOpen*/ ctx[0] !== void 0) {
		hamburger_props.open = /*menuIsOpen*/ ctx[0];
	}

	hamburger = new Hamburger({ props: hamburger_props });
	binding_callbacks.push(() => bind(hamburger, "open", hamburger_open_binding));
	let if_block = /*menuIsOpen*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			create_component(hamburger.$$.fragment);
			t = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			mount_component(hamburger, target, anchor);
			insert(target, t, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const hamburger_changes = {};

			if (!updating_open && dirty & /*menuIsOpen*/ 1) {
				updating_open = true;
				hamburger_changes.open = /*menuIsOpen*/ ctx[0];
				add_flush_callback(() => updating_open = false);
			}

			hamburger.$set(hamburger_changes);

			if (/*menuIsOpen*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*menuIsOpen*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(hamburger.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(hamburger.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			destroy_component(hamburger, detaching);
			if (detaching) detach(t);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let menuIsOpen;
	let selectedMenuItem = null;

	const menuItems = [
		{ id: 100, text: "à propos" },
		{ id: 102, text: "contact" },
		{ id: 103, text: "feedback" },
		{ id: 101, text: "remerciements" }
	];

	function onMenuClick(menuItem) {
		$$invalidate(1, selectedMenuItem = { id: menuItem.id, text: menuItem.text });
	}

	function onInfoViewRequestsClosing() {
		$$invalidate(1, selectedMenuItem = null);
	}

	function hamburger_open_binding(value) {
		menuIsOpen = value;
		$$invalidate(0, menuIsOpen);
	}

	const click_handler = menuItem => onMenuClick(menuItem);

	return [
		menuIsOpen,
		selectedMenuItem,
		menuItems,
		onMenuClick,
		onInfoViewRequestsClosing,
		hamburger_open_binding,
		click_handler
	];
}

class MainMenu extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-12404vn-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default MainMenu;