/* js/components/Map.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	binding_callbacks,
	check_outros,
	create_slot,
	detach,
	element,
	group_outros,
	init,
	insert,
	safe_not_equal,
	toggle_class,
	transition_in,
	transition_out,
	update_slot
} from "svelte/internal";

import { onMount, onDestroy, setContext } from "svelte";
import { adaptEsriMapStyle } from "../esriStyleAdapter.js";
import mapboxgl, { Map } from "mapbox-gl";
import { mapbox, key } from "../mapbox.js";
import { Config } from "../config.js";
import { fakeGeoLocationProvider } from "../fakeGeoLocationProvider.js";
import { mapEventProvider } from "../mapEventProvider.js";
import { flyToProvider } from "../providers.js";
import { genevaCoords } from "../geoUtils";

function create_if_block(ctx) {
	let current;
	const default_slot_template = /*$$slots*/ ctx[5].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[4], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && dirty & /*$$scope*/ 16) {
					update_slot(default_slot, default_slot_template, ctx, /*$$scope*/ ctx[4], dirty, null, null);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let current;
	let if_block = /*map*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			attr(div, "class", "map");
			toggle_class(div, "map-left", /*pushLeft*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
			/*div_binding*/ ctx[6](div);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*map*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*map*/ 2) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			if (dirty & /*pushLeft*/ 1) {
				toggle_class(div, "map-left", /*pushLeft*/ ctx[0]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
			/*div_binding*/ ctx[6](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { onLoad } = $$props;
	let { pushLeft = false } = $$props;

	// $: console.log("push left", pushLeft);
	let map;

	let container;
	setContext(key, { getMap: () => map });

	function onFlyToRequest(request) {
		if (request.coordinates) {
			const options = {
				center: request.coordinates,
				zoom: 14,
				speed: 0.25
			};

			map.flyTo(options);
			return;
		}

		if (request.bounds) {
			const options = { padding: 60, duration: 1500 };
			map.fitBounds(request.bounds, options);
			return;
		}
	}

	onMount(async () => {
		const response = await fetch("./mapstyle.json");
		const esriMapStyle = await response.json();
		const mapStyle = await adaptEsriMapStyle(esriMapStyle);

		// SW, NE
		const maxBounds = [[5.4052734375, 45.838367585245855], [7.248229980468751, 46.66263249079177]];

		mapboxgl.accessToken = "ezree";

		$$invalidate(1, map = new Map({
				container, // container id
				style: mapStyle,
				pitch: 50, // pitch in degrees
				center: genevaCoords,
				zoom: 12, // starting zoom,
				minZoom: 9,
				maxZoom: 18,
				maxBounds,
				attributionControl: false
			}));

		if (Config.useFakeGpsProvider) {
			fakeGeoLocationProvider.initMap(map);
		}

		mapEventProvider.initMap(map);
		flyToProvider.addListener(onFlyToRequest);

		map.on("load", function () {
			console.log("map loaded and painted");

			if (onLoad) {
				onLoad();
			}
		});

		// TODO: move this to a better place 
		window.addEventListener("orientationchange", hackIosRotationSnaffu);

		function hackIosRotationSnaffu() {
			// console.log("hack ios rotation snaffu");
			document.documentElement.style.height = `initial`;

			setTimeout(
				() => {
					document.documentElement.style.height = `100%`;
					map.resize();

					setTimeout(
						() => {
							// this line prevents the content
							// from hiding behind the address bar
							window.scrollTo(0, 1);
						},
						500
					);
				},
				500
			);
		}

		return () => {
			window.removeEventListener(hackIosRotationSnaffu);
			map.remove();
		};
	});

	let { $$slots = {}, $$scope } = $$props;

	function div_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			container = $$value;
			$$invalidate(2, container);
		});
	}

	$$self.$set = $$props => {
		if ("onLoad" in $$props) $$invalidate(3, onLoad = $$props.onLoad);
		if ("pushLeft" in $$props) $$invalidate(0, pushLeft = $$props.pushLeft);
		if ("$$scope" in $$props) $$invalidate(4, $$scope = $$props.$$scope);
	};

	return [pushLeft, map, container, onLoad, $$scope, $$slots, div_binding];
}

class Map_1 extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { onLoad: 3, pushLeft: 0 });
	}
}

export default Map_1;