/* js/components/CanvasComponent.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	binding_callbacks,
	detach,
	element,
	globals,
	init,
	insert,
	listen,
	noop,
	safe_not_equal
} from "svelte/internal";

const { window: window_1 } = globals;
import { onMount, onDestroy } from "svelte";
import { cubicOut, cubicInOut } from "svelte/easing";

function add_css() {
	var style = element("style");
	style.id = "svelte-1cn9ery-style";
	style.textContent = "canvas.svelte-1cn9ery{position:absolute;z-index:1500}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let canvas_1;
	let mounted;
	let dispose;
	add_render_callback(/*onwindowresize*/ ctx[4]);

	return {
		c() {
			canvas_1 = element("canvas");
			attr(canvas_1, "width", /*screenWidth*/ ctx[1]);
			attr(canvas_1, "height", /*screenHeight*/ ctx[2]);
			attr(canvas_1, "class", "svelte-1cn9ery");
		},
		m(target, anchor) {
			insert(target, canvas_1, anchor);
			/*canvas_1_binding*/ ctx[5](canvas_1);

			if (!mounted) {
				dispose = listen(window_1, "resize", /*onwindowresize*/ ctx[4]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*screenWidth*/ 2) {
				attr(canvas_1, "width", /*screenWidth*/ ctx[1]);
			}

			if (dirty & /*screenHeight*/ 4) {
				attr(canvas_1, "height", /*screenHeight*/ ctx[2]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(canvas_1);
			/*canvas_1_binding*/ ctx[5](null);
			mounted = false;
			dispose();
		}
	};
}

const white = "white";
const transparent = "rgba(0, 0, 0, 1)";

function instance($$self, $$props, $$invalidate) {
	let { duration = 1000 } = $$props;
	let canvas;
	let ctx;
	let start;
	let screenWidth;
	let screenHeight;
	let animationFrameId = -1;
	let animationPlaying = false;
	let orientationChangeTimeoutId = -1;

	// $:{
	//     console.log(screenWidth, screenHeight);
	// }
	function onOrientationChange() {
		if (animationPlaying) {
			stopAnimation();
		}

		clearTimeout(orientationChangeTimeoutId);
		setTimeout(() => drawFinalState(), 1100);
	}

	onMount(() => {
		ctx = canvas.getContext("2d");
		startAnimation(duration);
		window.addEventListener("orientationchange", onOrientationChange);
	});

	onDestroy(() => {
		cancelAnimationFrame(animationFrameId);
		window.removeEventListener("orientationchange", onOrientationChange);
	});

	function stopAnimation() {
		// console.log("stop animation");
		cancelAnimationFrame(animationFrameId);

		animationPlaying = true;
	}

	function startAnimation(duration) {
		// console.log("start animation");
		start = undefined;

		cancelAnimationFrame(animationFrameId);
		animationFrameId = window.requestAnimationFrame(step);
	}

	function interpolate(start, end, time) {
		const t = cubicOut(time); // or any other easing function see import section
		return start * (1 - t) + end * t;
	}

	function computeEndRect() {
		const width = screenWidth < screenHeight ? screenWidth : screenHeight;
		const endWidth = width * 0.95;

		//   const endHeight = width * 0.9;
		const endHeight = screenHeight * 0.975;

		const endRect = {
			x: screenWidth / 2 - endWidth / 2,
			//     y:screenHeight * 0.1,
			y: screenWidth / 2 - endWidth / 2,
			width: endWidth,
			height: endHeight
		};

		return endRect;
	}

	function computeStartRect() {
		const rect = {
			x: 0,
			y: 0,
			width: canvas.width,
			height: canvas.height
		};

		return rect;
	}

	function step(timestamp) {
		animationPlaying = true;

		if (start === undefined) {
			start = timestamp;
		}

		const elapsed = timestamp - start;
		const startRect = computeStartRect();
		const endRect = computeEndRect();
		const t = elapsed / duration;

		const current = {
			x: interpolate(startRect.x, endRect.x, t),
			y: interpolate(startRect.y, endRect.y, t),
			width: interpolate(startRect.width, endRect.width, t),
			height: interpolate(startRect.height, endRect.height, t)
		};

		ctx.globalCompositeOperation = "source-over";
		ctx.fillStyle = white;
		ctx.fillRect(0, 0, canvas.width, canvas.height);
		ctx.fillStyle = transparent;
		ctx.globalCompositeOperation = "destination-out";
		ctx.fillRect(current.x, current.y, current.width, current.height);

		if (elapsed < duration) {
			animationFrameId = window.requestAnimationFrame(step);
		} else {
			animationFrameId = -1;
			animationPlaying = false;
		}
	}

	function drawFinalState() {
		// console.log("draw final State");
		ctx.globalCompositeOperation = "source-over";

		ctx.fillStyle = white;
		ctx.fillRect(0, 0, screenWidth, screenHeight);
		const endRect = computeEndRect();
		ctx.fillStyle = transparent;
		ctx.globalCompositeOperation = "destination-out";
		ctx.fillRect(endRect.x, endRect.y, endRect.width, endRect.height);
	}

	function onwindowresize() {
		$$invalidate(1, screenWidth = window_1.innerWidth)
		$$invalidate(2, screenHeight = window_1.innerHeight)
	}

	function canvas_1_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			canvas = $$value;
			$$invalidate(0, canvas);
		});
	}

	$$self.$set = $$props => {
		if ("duration" in $$props) $$invalidate(3, duration = $$props.duration);
	};

	return [canvas, screenWidth, screenHeight, duration, onwindowresize, canvas_1_binding];
}

class CanvasComponent extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1cn9ery-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { duration: 3 });
	}
}

export default CanvasComponent;