/* js/components/Notification.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	create_bidirectional_transition,
	detach,
	element,
	init,
	insert,
	safe_not_equal,
	set_data,
	text,
	toggle_class
} from "svelte/internal";

import { fade } from "svelte/transition";
import { onMount, onDestroy } from "svelte";
import { createEventDispatcher } from "svelte";
import { NotificationType } from "../notificationStore.js";

function create_fragment(ctx) {
	let div;
	let span;
	let t;
	let div_transition;
	let current;

	return {
		c() {
			div = element("div");
			span = element("span");
			t = text(/*message*/ ctx[0]);
			attr(div, "class", "notification");
			toggle_class(div, "notification-success", /*type*/ ctx[1] == NotificationType.SUCCESS);
			toggle_class(div, "notification-warning", /*type*/ ctx[1] == NotificationType.WARNING);
			toggle_class(div, "notification-error", /*type*/ ctx[1] == NotificationType.ERROR);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, span);
			append(span, t);
			current = true;
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*message*/ 1) set_data(t, /*message*/ ctx[0]);

			if (dirty & /*type, NotificationType*/ 2) {
				toggle_class(div, "notification-success", /*type*/ ctx[1] == NotificationType.SUCCESS);
			}

			if (dirty & /*type, NotificationType*/ 2) {
				toggle_class(div, "notification-warning", /*type*/ ctx[1] == NotificationType.WARNING);
			}

			if (dirty & /*type, NotificationType*/ 2) {
				toggle_class(div, "notification-error", /*type*/ ctx[1] == NotificationType.ERROR);
			}
		},
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (!div_transition) div_transition = create_bidirectional_transition(div, fade, {}, true);
				div_transition.run(1);
			});

			current = true;
		},
		o(local) {
			if (!div_transition) div_transition = create_bidirectional_transition(div, fade, {}, false);
			div_transition.run(0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (detaching && div_transition) div_transition.end();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { duration = 2000 } = $$props;
	let { message = "" } = $$props;
	let { id = 0 } = $$props;
	let { type = NotificationType.SUCCESS } = $$props;
	const dispatch = createEventDispatcher();

	onMount(() => {
		// console.log("mount notif", id);
		setTimeout(
			() => {
				// console.log("notification done", id);
				dispatch("complete", { id });
			},
			duration
		);
	});

	$$self.$set = $$props => {
		if ("duration" in $$props) $$invalidate(2, duration = $$props.duration);
		if ("message" in $$props) $$invalidate(0, message = $$props.message);
		if ("id" in $$props) $$invalidate(3, id = $$props.id);
		if ("type" in $$props) $$invalidate(1, type = $$props.type);
	};

	return [message, type, duration, id];
}

class Notification extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { duration: 2, message: 0, id: 3, type: 1 });
	}
}

export default Notification;