/* js/components/SplashScreen.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

import { createEventDispatcher, onMount } from "svelte";

function add_css() {
	var style = element("style");
	style.id = "svelte-hdkil-style";
	style.textContent = ".center.svelte-hdkil{display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%}img.svelte-hdkil{height:90%}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.innerHTML = `<img src="./AG_Splashscreen.svg" alt="splashscreen image" class="svelte-hdkil">`;
			attr(div, "class", "center svelte-hdkil");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

const duration = 3500;

function instance($$self) {
	const dispatchEvent = createEventDispatcher();

	function onScreenComplete() {
		dispatchEvent("complete", {});
	}

	onMount(() => {
		let timeoutId = setTimeout(onScreenComplete, duration);
	});

	return [];
}

class SplashScreen extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-hdkil-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default SplashScreen;