/* js/components/FormattedTime.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	text
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-158u9vf-style";
	style.textContent = "span.svelte-158u9vf{display:block}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let span;
	let t;

	return {
		c() {
			span = element("span");
			t = text(/*formattedTime*/ ctx[0]);
			attr(span, "class", "svelte-158u9vf");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t);
		},
		p(ctx, [dirty]) {
			if (dirty & /*formattedTime*/ 1) set_data(t, /*formattedTime*/ ctx[0]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

function isNumber(n) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}

function pad(n, width, z) {
	z = z || "0";
	n = n + "";

	return n.length >= width
	? n
	: new Array(width - n.length + 1).join(z) + n;
}

function format(seconds) {
	if (!isNumber(seconds)) {
		return "";
	}

	let t = parseInt(seconds);
	const hours = Math.floor(t / 3600);
	const minutes = Math.floor(t / 60);
	const secs = t % 60;
	let result = "";
	if (hours) result += `${hours}:`;

	if (minutes) {
		result += `${pad(minutes, 2)}:`;
	} else {
		result += "00:";
	}

	result += `${pad(secs, 2)}`;
	return result;
}

function instance($$self, $$props, $$invalidate) {
	let { seconds } = $$props;

	$$self.$set = $$props => {
		if ("seconds" in $$props) $$invalidate(1, seconds = $$props.seconds);
	};

	let formattedTime;

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*seconds*/ 2) {
			$: $$invalidate(0, formattedTime = format(seconds));
		}
	};

	return [formattedTime, seconds];
}

class FormattedTime extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-158u9vf-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { seconds: 1 });
	}
}

export default FormattedTime;