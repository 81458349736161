/* js/components/mainMenu/MainMenu_apropos.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let h1;
	let br0;
	let t1;
	let h20;
	let t4;
	let h21;
	let t7;
	let br3;
	let br4;
	let t8;
	let div0;
	let t20;
	let p;
	let t21;
	let div1;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Audiogenese";
			br0 = element("br");
			t1 = space();
			h20 = element("h2");
			h20.innerHTML = `Un parcours sonore<br>virtuel et in situ`;
			t4 = space();
			h21 = element("h2");
			h21.innerHTML = `15.8<br>— 15.9.2020`;
			t7 = space();
			br3 = element("br");
			br4 = element("br");
			t8 = space();
			div0 = element("div");

			div0.innerHTML = `
      Des propositions musicales et sonores vous attendent, disséminées dans des lieux particuliers. Composés par une équipe de musiciens hors pair, ces moments d&#39;écoute vous feront vivre chaque lieu d&#39;une manière personnelle, à travers le regard de 8 artistes sonores.
        <br>  
        <br> 
        L&#39;application que vous avez entre les mains vous guidera vers ces lieux. Veuillez s&#39;il vous-plaît confirmer l&#39;activation du GPS, c&#39;est la technologie que nous utilisons. Il n&#39;y a pas de traçage, les données ne quittent pas votre appareil mobile.
        <br>  
        <br> 
        Ce projet est en partie inspiré par le travail de Peter Greenaway, qui proposait d’observer le paysage à travers son œil dans ses installations «Stairs».
        <br>  
        <br> 
        N&#39;oubliez pas de prendre un casque audio avec vous pour profiter pleinement de l&#39;expérience. Bonne écoute à tous!
        <br>  
        <br> 
        <br>
        D. Delcourt / D. Hodgetts<br>août 2020
        

    `;

			t20 = space();
			p = element("p");
			p.innerHTML = `<br><br><br><br><br><br>`;
			t21 = space();
			div1 = element("div");
			div1.innerHTML = `<a href="mailto:contact@audiogenese.net"><button class="button">Contact</button></a>`;
			attr(h1, "class", "indent");
			attr(h21, "class", "indent");
			attr(div0, "class", "text-indent");
			attr(div1, "class", "buttonWrapper");
			set_style(div1, "z-index", "3001");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, br0, anchor);
			insert(target, t1, anchor);
			insert(target, h20, anchor);
			insert(target, t4, anchor);
			insert(target, h21, anchor);
			insert(target, t7, anchor);
			insert(target, br3, anchor);
			insert(target, br4, anchor);
			insert(target, t8, anchor);
			insert(target, div0, anchor);
			insert(target, t20, anchor);
			insert(target, p, anchor);
			insert(target, t21, anchor);
			insert(target, div1, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(br0);
			if (detaching) detach(t1);
			if (detaching) detach(h20);
			if (detaching) detach(t4);
			if (detaching) detach(h21);
			if (detaching) detach(t7);
			if (detaching) detach(br3);
			if (detaching) detach(br4);
			if (detaching) detach(t8);
			if (detaching) detach(div0);
			if (detaching) detach(t20);
			if (detaching) detach(p);
			if (detaching) detach(t21);
			if (detaching) detach(div1);
		}
	};
}

class MainMenu_apropos extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default MainMenu_apropos;