/* js/components/interviews/ArtistInfo3.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let h1;
	let t1;
	let div0;
	let t5;
	let p0;
	let t7;
	let div1;
	let t9;
	let p1;
	let t11;
	let div2;
	let t13;
	let p2;
	let t15;
	let div3;
	let t17;
	let p3;
	let t19;
	let div4;
	let t21;
	let p4;
	let t23;
	let div5;
	let t25;
	let p5;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Vincent Hänni";
			t1 = space();
			div0 = element("div");

			div0.innerHTML = `
          Né à Genève en 1972. Guitariste, il est depuis toujours passionné par le son et l’expérimentation sonore.  Il aime autant la synthèse modulaire que la pratique des instruments acoustiques tels que la guitare et le luth. Il a composé la musique de plus d’une trentaine de films, pièces de théâtre ou chorégraphies. De 2007 à 2014, il intègre le groupe The Young Gods avec lequel il compose plusieurs albums et se produit internationalement. <br><br>Il collabore avec de nombreux acteurs de la scène expérimentale et théâtrale helvétique, dont Jacques Demierre, Maya Bösch, La Cave 12 ou encore Cindy Van Acker. <br><br>En 2014, il remporte avec Rudy Decelière la résidence artistique Collide@CERN. En 2016, ils cosignent l’installation sonore “Horizons irrésolus”, fruit d’un travail de recherche de près de deux ans avec Robert Kieffer et Diego Blas, deux physiciens du CERN.
        `;

			t5 = space();
			p0 = element("p");
			p0.textContent = "Quel est ton nom d’artiste?";
			t7 = space();
			div1 = element("div");
			div1.textContent = "vincent hänni";
			t9 = space();
			p1 = element("p");
			p1.textContent = "D’où viens-tu?";
			t11 = space();
			div2 = element("div");
			div2.textContent = "genève";
			t13 = space();
			p2 = element("p");
			p2.textContent = "Pourquoi as-tu décidé de faire partie du projet?";
			t15 = space();
			div3 = element("div");
			div3.textContent = "parce qu'il est intéressant et que le lieu est beau.";
			t17 = space();
			p3 = element("p");
			p3.textContent = "Pourquoi as-tu choisis ce lieu pour l’écoute de ta musique?";
			t19 = space();
			div4 = element("div");
			div4.textContent = "parce qu'il est courbe et qu'il bouge.";
			t21 = space();
			p4 = element("p");
			p4.textContent = "Quels sont tes envies pour le futur?";
			t23 = space();
			div5 = element("div");
			div5.textContent = "j'aimerais comprendre la thérorie de fourier en détail.";
			t25 = space();
			p5 = element("p");
			p5.innerHTML = `<br><br><br><br>`;
			attr(div0, "class", "text-indent");
			attr(p0, "class", "interview_question");
			attr(div1, "class", "text-indent");
			attr(p1, "class", "interview_question");
			attr(div2, "class", "text-indent");
			attr(p2, "class", "interview_question");
			attr(div3, "class", "text-indent");
			attr(p3, "class", "interview_question");
			attr(div4, "class", "text-indent");
			attr(p4, "class", "interview_question");
			attr(div5, "class", "text-indent");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, div0, anchor);
			insert(target, t5, anchor);
			insert(target, p0, anchor);
			insert(target, t7, anchor);
			insert(target, div1, anchor);
			insert(target, t9, anchor);
			insert(target, p1, anchor);
			insert(target, t11, anchor);
			insert(target, div2, anchor);
			insert(target, t13, anchor);
			insert(target, p2, anchor);
			insert(target, t15, anchor);
			insert(target, div3, anchor);
			insert(target, t17, anchor);
			insert(target, p3, anchor);
			insert(target, t19, anchor);
			insert(target, div4, anchor);
			insert(target, t21, anchor);
			insert(target, p4, anchor);
			insert(target, t23, anchor);
			insert(target, div5, anchor);
			insert(target, t25, anchor);
			insert(target, p5, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(div0);
			if (detaching) detach(t5);
			if (detaching) detach(p0);
			if (detaching) detach(t7);
			if (detaching) detach(div1);
			if (detaching) detach(t9);
			if (detaching) detach(p1);
			if (detaching) detach(t11);
			if (detaching) detach(div2);
			if (detaching) detach(t13);
			if (detaching) detach(p2);
			if (detaching) detach(t15);
			if (detaching) detach(div3);
			if (detaching) detach(t17);
			if (detaching) detach(p3);
			if (detaching) detach(t19);
			if (detaching) detach(div4);
			if (detaching) detach(t21);
			if (detaching) detach(p4);
			if (detaching) detach(t23);
			if (detaching) detach(div5);
			if (detaching) detach(t25);
			if (detaching) detach(p5);
		}
	};
}

class ArtistInfo3 extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default ArtistInfo3;