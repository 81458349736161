import { writable } from 'svelte/store';

export const GeoLocationState = {
    PENDING:"pending",
    PROCESSING_REQUEST: "processing_request",
    TIMEOUT_ERROR: "timeout_error",
    POSITION_UNAVAILABLE_ERROR: "position_unavailable_error",
    GRANTED: "granted",
    DENIED: "denied",
};

function createLocationPermissionStatusStore(){
    let geoLocationState = GeoLocationState.PROCESSING_REQUEST;

    const { subscribe, set } = writable(geoLocationState);

    return {
        subscribe,
        clear: () => {
            // only for debug purposes
            set(GeoLocationState.PENDING);
        },
        initGeoLocation: () =>{
            set(GeoLocationState.PROCESSING_REQUEST);
        },
        setTimeoutError: () => {
            set(GeoLocationState.TIMEOUT_ERROR);
        },
        setPositionUnavailableError: () => {
            set(GeoLocationState.POSITION_UNAVAILABLE_ERROR);
        },
        setLocationGranted: () => {
            set(GeoLocationState.GRANTED);
        },
        setLocationDenied: () => {
            set(GeoLocationState.DENIED);
        },
    };
}

export const geoLocationState = createLocationPermissionStatusStore();