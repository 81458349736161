/* js/components/Logo.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	toggle_class
} from "svelte/internal";

function create_fragment(ctx) {
	let div;

	return {
		c() {
			div = element("div");

			div.innerHTML = `<table style="width =25%" border="0" class="container"><tr><td style="width:25%">au<br>dio</td> 
            <td style="width:25%">ge<br>nese</td> 
            <td style="width:50%" align="right" valign="top">.net</td></tr></table>`;

			attr(div, "class", "logo noselect");
			toggle_class(div, "logo-absolute", /*absolutePosition*/ ctx[0]);
			toggle_class(div, "neg", /*absolutePosition*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p(ctx, [dirty]) {
			if (dirty & /*absolutePosition*/ 1) {
				toggle_class(div, "logo-absolute", /*absolutePosition*/ ctx[0]);
			}

			if (dirty & /*absolutePosition*/ 1) {
				toggle_class(div, "neg", /*absolutePosition*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { absolutePosition = false } = $$props;

	$$self.$set = $$props => {
		if ("absolutePosition" in $$props) $$invalidate(0, absolutePosition = $$props.absolutePosition);
	};

	return [absolutePosition];
}

class Logo extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { absolutePosition: 0 });
	}
}

export default Logo;