/* js/components/NotificationDisplay.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	outro_and_destroy_block,
	safe_not_equal,
	transition_in,
	transition_out,
	update_keyed_each
} from "svelte/internal";

import Notification from "./Notification.svelte";
import { onMount, onDestroy } from "svelte";
import { notificationStore } from "../notificationStore.js";
import { tick } from "svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[3] = list[i];
	return child_ctx;
}

// (35:4) {#each notifications as notif (notif.id) }
function create_each_block(key_1, ctx) {
	let first;
	let notification;
	let current;

	notification = new Notification({
			props: {
				duration: notificationDuration,
				id: /*notif*/ ctx[3].id,
				type: /*notif*/ ctx[3].type,
				message: /*notif*/ ctx[3].message
			}
		});

	notification.$on("complete", /*notifCompleted*/ ctx[1]);

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(notification.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(notification, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const notification_changes = {};
			if (dirty & /*notifications*/ 1) notification_changes.id = /*notif*/ ctx[3].id;
			if (dirty & /*notifications*/ 1) notification_changes.type = /*notif*/ ctx[3].type;
			if (dirty & /*notifications*/ 1) notification_changes.message = /*notif*/ ctx[3].message;
			notification.$set(notification_changes);
		},
		i(local) {
			if (current) return;
			transition_in(notification.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(notification.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(first);
			destroy_component(notification, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let current;
	let each_value = /*notifications*/ ctx[0];
	const get_key = ctx => /*notif*/ ctx[3].id;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "notifications");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*notificationDuration, notifications, notifCompleted*/ 3) {
				const each_value = /*notifications*/ ctx[0];
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div, outro_and_destroy_block, create_each_block, null, get_each_context);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}
		}
	};
}

let counter = 0;
const notificationDuration = 2000;

function instance($$self, $$props, $$invalidate) {
	let notifications = [];

	function onNotification(notification) {
		$$invalidate(0, notifications = [notification, ...notifications]);
	} // console.log("got notification------------", notification.id);

	onMount(() => {
		notificationStore.addListener(onNotification);
	});

	onDestroy(() => {
		notificationStore.removeListener(onNotification);
	});

	function notifCompleted(e) {
		$$invalidate(0, notifications = notifications.filter(n => n.id != e.detail.id));
		notifications.forEach(n => console.log(n.id));
	}

	return [notifications, notifCompleted];
}

class NotificationDisplay extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default NotificationDisplay;