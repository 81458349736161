/* js/components/MainLoop.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	binding_callbacks,
	check_outros,
	create_component,
	destroy_component,
	detach,
	empty,
	globals,
	group_outros,
	init,
	insert,
	mount_component,
	outro_and_destroy_block,
	safe_not_equal,
	space,
	transition_in,
	transition_out,
	update_keyed_each
} from "svelte/internal";

const { Map: Map_1 } = globals;
import Map, { foo } from "./Map.svelte";
import UserMarker from "./UserMarker.svelte";
import Logo from "./Logo.svelte";
import SpotMarker from "./SpotMarker.svelte";
import MeCenterButton from "./MeCenterButton.svelte";
import SpotView from "./SpotView.svelte";
import SpotList from "./SpotList.svelte";
import { SpotListState } from "../spotListState.js";
import SelectedSpotFooter from "./SelectedSpotFooter.svelte";
import { geoLocationProvider } from "../geoLocationStore.js";
import { onMount } from "svelte";
import { notificationStore } from "../notificationStore.js";
import { spotInRangeOfCoord, distance } from "../geoUtils.js";
import { mapEventProvider } from "../mapEventProvider.js";
import MapUserTracker from "../mapUserTracker.js";
import MainMenu from "./MainMenu.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[29] = list[i];
	return child_ctx;
}

// (213:0) {#if mapIsReady}
function create_if_block_2(ctx) {
	let mecenterbutton;
	let current;

	mecenterbutton = new MeCenterButton({
			props: {
				active: /*userTrackingActive*/ ctx[7],
				clickHandler: /*onMeCenterClick*/ ctx[16]
			}
		});

	return {
		c() {
			create_component(mecenterbutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(mecenterbutton, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const mecenterbutton_changes = {};
			if (dirty[0] & /*userTrackingActive*/ 128) mecenterbutton_changes.active = /*userTrackingActive*/ ctx[7];
			mecenterbutton.$set(mecenterbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(mecenterbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(mecenterbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(mecenterbutton, detaching);
		}
	};
}

// (219:0) {#if spotViewOpen}
function create_if_block_1(ctx) {
	let spotview;
	let current;

	spotview = new SpotView({
			props: { spot: /*onLocationSpot*/ ctx[3] }
		});

	spotview.$on("close-request", /*spotViewRequestsClosing*/ ctx[11]);

	return {
		c() {
			create_component(spotview.$$.fragment);
		},
		m(target, anchor) {
			mount_component(spotview, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const spotview_changes = {};
			if (dirty[0] & /*onLocationSpot*/ 8) spotview_changes.spot = /*onLocationSpot*/ ctx[3];
			spotview.$set(spotview_changes);
		},
		i(local) {
			if (current) return;
			transition_in(spotview.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(spotview.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(spotview, detaching);
		}
	};
}

// (238:4) {#if spots}
function create_if_block(ctx) {
	let each_blocks = [];
	let each_1_lookup = new Map_1();
	let each_1_anchor;
	let current;
	let each_value = /*spots*/ ctx[1];
	const get_key = ctx => /*spot*/ ctx[29].id;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*spots, onSpotRequestsSelection*/ 8194) {
				const each_value = /*spots*/ ctx[1];
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, each_1_anchor.parentNode, outro_and_destroy_block, create_each_block, each_1_anchor, get_each_context);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d(detaching);
			}

			if (detaching) detach(each_1_anchor);
		}
	};
}

// (239:8) {#each spots  as spot (spot.id)}
function create_each_block(key_1, ctx) {
	let first;
	let spotmarker;
	let current;

	spotmarker = new SpotMarker({
			props: {
				id: /*spot*/ ctx[29].id,
				selected: /*spot*/ ctx[29].selected,
				coords: /*spot*/ ctx[29].coords,
				radius: /*spot*/ ctx[29].radius
			}
		});

	spotmarker.$on("requestSelection", /*onSpotRequestsSelection*/ ctx[13]);

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(spotmarker.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(spotmarker, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const spotmarker_changes = {};
			if (dirty[0] & /*spots*/ 2) spotmarker_changes.id = /*spot*/ ctx[29].id;
			if (dirty[0] & /*spots*/ 2) spotmarker_changes.selected = /*spot*/ ctx[29].selected;
			if (dirty[0] & /*spots*/ 2) spotmarker_changes.coords = /*spot*/ ctx[29].coords;
			if (dirty[0] & /*spots*/ 2) spotmarker_changes.radius = /*spot*/ ctx[29].radius;
			spotmarker.$set(spotmarker_changes);
		},
		i(local) {
			if (current) return;
			transition_in(spotmarker.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(spotmarker.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(first);
			destroy_component(spotmarker, detaching);
		}
	};
}

// (236:0) <Map bind:this={map} pushLeft={mapIsPushedToTheLeft} onLoad={onMapLoaded}>
function create_default_slot(ctx) {
	let usermarker;
	let t;
	let if_block_anchor;
	let current;
	usermarker = new UserMarker({});
	let if_block = /*spots*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			create_component(usermarker.$$.fragment);
			t = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			mount_component(usermarker, target, anchor);
			insert(target, t, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (/*spots*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty[0] & /*spots*/ 2) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(usermarker.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(usermarker.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			destroy_component(usermarker, detaching);
			if (detaching) detach(t);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function create_fragment(ctx) {
	let mainmenu;
	let t0;
	let t1;
	let t2;
	let spotlist;
	let t3;
	let selectedspotfooter;
	let t4;
	let map_1;
	let current;
	mainmenu = new MainMenu({});
	let if_block0 = /*mapIsReady*/ ctx[6] && create_if_block_2(ctx);
	let if_block1 = /*spotViewOpen*/ ctx[4] && create_if_block_1(ctx);

	spotlist = new SpotList({
			props: {
				spots: /*spots*/ ctx[1],
				spotListState: /*spotListState*/ ctx[5]
			}
		});

	spotlist.$on("closeRequest", /*spotListRequestsClosing*/ ctx[12]);
	spotlist.$on("selectionRequest", /*spotListRequestSpotSelection*/ ctx[14]);

	selectedspotfooter = new SelectedSpotFooter({
			props: {
				isOpen: /*footerIsOpen*/ ctx[8],
				spot: /*userSelectedSpot*/ ctx[2]
			}
		});

	selectedspotfooter.$on("click", /*onFooterClick*/ ctx[15]);

	let map_1_props = {
		pushLeft: /*mapIsPushedToTheLeft*/ ctx[9],
		onLoad: /*onMapLoaded*/ ctx[10],
		$$slots: { default: [create_default_slot] },
		$$scope: { ctx }
	};

	map_1 = new Map({ props: map_1_props });
	/*map_1_binding*/ ctx[17](map_1);

	return {
		c() {
			create_component(mainmenu.$$.fragment);
			t0 = space();
			if (if_block0) if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			t2 = space();
			create_component(spotlist.$$.fragment);
			t3 = space();
			create_component(selectedspotfooter.$$.fragment);
			t4 = space();
			create_component(map_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(mainmenu, target, anchor);
			insert(target, t0, anchor);
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t1, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, t2, anchor);
			mount_component(spotlist, target, anchor);
			insert(target, t3, anchor);
			mount_component(selectedspotfooter, target, anchor);
			insert(target, t4, anchor);
			mount_component(map_1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (/*mapIsReady*/ ctx[6]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty[0] & /*mapIsReady*/ 64) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(t1.parentNode, t1);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*spotViewOpen*/ ctx[4]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty[0] & /*spotViewOpen*/ 16) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(t2.parentNode, t2);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			const spotlist_changes = {};
			if (dirty[0] & /*spots*/ 2) spotlist_changes.spots = /*spots*/ ctx[1];
			if (dirty[0] & /*spotListState*/ 32) spotlist_changes.spotListState = /*spotListState*/ ctx[5];
			spotlist.$set(spotlist_changes);
			const selectedspotfooter_changes = {};
			if (dirty[0] & /*footerIsOpen*/ 256) selectedspotfooter_changes.isOpen = /*footerIsOpen*/ ctx[8];
			if (dirty[0] & /*userSelectedSpot*/ 4) selectedspotfooter_changes.spot = /*userSelectedSpot*/ ctx[2];
			selectedspotfooter.$set(selectedspotfooter_changes);
			const map_1_changes = {};
			if (dirty[0] & /*mapIsPushedToTheLeft*/ 512) map_1_changes.pushLeft = /*mapIsPushedToTheLeft*/ ctx[9];

			if (dirty[0] & /*spots*/ 2 | dirty[1] & /*$$scope*/ 2) {
				map_1_changes.$$scope = { dirty, ctx };
			}

			map_1.$set(map_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(mainmenu.$$.fragment, local);
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(spotlist.$$.fragment, local);
			transition_in(selectedspotfooter.$$.fragment, local);
			transition_in(map_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(mainmenu.$$.fragment, local);
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(spotlist.$$.fragment, local);
			transition_out(selectedspotfooter.$$.fragment, local);
			transition_out(map_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(mainmenu, detaching);
			if (detaching) detach(t0);
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t1);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(t2);
			destroy_component(spotlist, detaching);
			if (detaching) detach(t3);
			destroy_component(selectedspotfooter, detaching);
			if (detaching) detach(t4);
			/*map_1_binding*/ ctx[17](null);
			destroy_component(map_1, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const baseSpots = require("../../data/spots.json");
	let map;
	let spots = null;
	let userSelectedSpot = null;
	let onLocationSpot = null;
	let spotViewOpen = false;
	let spotListState = SpotListState.CLOSED;
	let latestValidUserCoords = null;
	let mapIsReady = false;
	let userTrackingActive = false;
	const mapUserTracker = new MapUserTracker();

	const updateSpots = spots => {
		const userSelectedSpotId = userSelectedSpot ? userSelectedSpot.id : -1;

		return spots.map(s => {
			s.selected = s.id == userSelectedSpotId ? true : false;
			s.exitRadius = s.radius * 1.5;
			return s;
		});
	};

	function setupSpots() {
		$$invalidate(1, spots = updateSpots(baseSpots));
		console.log("spots loaded and ready");
	}

	function onMapLoaded() {
		setupSpots();
		$$invalidate(5, spotListState = SpotListState.OPEN);
		$$invalidate(6, mapIsReady = true);
	}

	function onMapEvent(event) {
		// console.log("map event", event.type);
		if (event.type === "touchmove") {
			disableUserTracking();
		}
	}

	onMount(() => {
		mapEventProvider.addListener(onMapEvent);
		const unsubscribe = geoLocationProvider.subscribe(onNewCoods);

		return () => {
			unsubscribe();
			disableUserTracking();
			mapEventProvider.removeListener(onMapEvent);
		};
	});

	function onNewCoods(val) {
		if (spotViewOpen) {
			return;
		}

		if (val.error && val.error.code == 2) {
			notificationStore.publishWarning("position unavailable error");
			return;
		}

		if (val.error && val.error.code == 3) {
			notificationStore.publishWarning("timeout error");
			return;
		}

		if (val.coords) {
			latestValidUserCoords = val.coords.slice();
			mapUserTracker.setGpsCoords(val.coords);
		}

		if (val.coords && spots) {
			// TODO extract and refactor this messy blob;
			if (onLocationSpot) {
				// check if we are leaving
				if (distance(onLocationSpot.coords, val.coords) > onLocationSpot.exitRadius) {
					// boum we have left the spot
					console.log("exiting spot", onLocationSpot.id);

					$$invalidate(3, onLocationSpot = null);
					$$invalidate(4, spotViewOpen = false);
				}
			} else {
				let maybeSpot = spotInRangeOfCoord(spots, val.coords);

				if (maybeSpot) {
					console.log("we are on location", maybeSpot.id);
					$$invalidate(3, onLocationSpot = maybeSpot);
					$$invalidate(2, userSelectedSpot = maybeSpot);
					mapUserTracker.setUserSelectedSpot(userSelectedSpot);
					$$invalidate(1, spots = updateSpots(spots));
					_paq.push(["trackEvent", "Progress", "SpotVisited", maybeSpot.id.toString()]);
					openSpotView();
				}
			}
		}
	}

	function openSpotView() {
		$$invalidate(4, spotViewOpen = true);
		disableUserTracking();
		$$invalidate(5, spotListState = SpotListState.CLOSED);
	}

	function spotViewRequestsClosing() {
		$$invalidate(4, spotViewOpen = false);
	}

	function spotListRequestsClosing() {
		$$invalidate(5, spotListState = SpotListState.CLOSED);
	}

	function shouldOpenSpotView(requestedSpotId) {
		if (userSelectedSpot && onLocationSpot && userSelectedSpot.id != onLocationSpot.id) {
			return false;
		}

		if (onLocationSpot && requestedSpotId == onLocationSpot.id) {
			return true;
		}

		return false;
	}

	function onSpotRequestsSelection(e) {
		console.log("spot requests selection", e.detail.id);

		// if(onLocationSpot){
		//     console.log("onlocation spot", onLocationSpot.id);
		// }
		// if(userSelectedSpot){
		//     console.log("user selected spot", userSelectedSpot.id);
		// }
		if (shouldOpenSpotView(e.detail.id)) {
			openSpotView();
		} else {
			if (!userSelectedSpot || userSelectedSpot.id != e.detail.id) {
				$$invalidate(2, userSelectedSpot = spots.find(s => s.id === e.detail.id));
				mapUserTracker.setUserSelectedSpot(userSelectedSpot);
				mapUserTracker.fly();
				$$invalidate(1, spots = updateSpots(spots));
			}
		}
	}

	function spotListRequestSpotSelection(e) {
		$$invalidate(5, spotListState = SpotListState.CLOSED);
		onSpotRequestsSelection(e);
	}

	function onFooterClick() {
		// TODO: refactor footer component
		$$invalidate(5, spotListState = SpotListState.OPEN);
	}

	function enableUserTracking() {
		mapUserTracker.startTracking();
		$$invalidate(7, userTrackingActive = true);
	}

	function disableUserTracking() {
		mapUserTracker.stopTracking();
		$$invalidate(7, userTrackingActive = false);
	}

	function onMeCenterClick() {
		enableUserTracking();
	}

	function map_1_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			map = $$value;
			$$invalidate(0, map);
		});
	}

	let footerIsOpen;
	let mapIsPushedToTheLeft;

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*userSelectedSpot, spotListState*/ 36) {
			$: $$invalidate(8, footerIsOpen = userSelectedSpot !== null && spotListState === SpotListState.CLOSED);
		}

		if ($$self.$$.dirty[0] & /*spotListState*/ 32) {
			$: $$invalidate(9, mapIsPushedToTheLeft = spotListState == SpotListState.OPEN);
		}
	};

	return [
		map,
		spots,
		userSelectedSpot,
		onLocationSpot,
		spotViewOpen,
		spotListState,
		mapIsReady,
		userTrackingActive,
		footerIsOpen,
		mapIsPushedToTheLeft,
		onMapLoaded,
		spotViewRequestsClosing,
		spotListRequestsClosing,
		onSpotRequestsSelection,
		spotListRequestSpotSelection,
		onFooterClick,
		onMeCenterClick,
		map_1_binding
	];
}

class MainLoop extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {}, [-1, -1]);
	}
}

export default MainLoop;