/* js/components/SpotList.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	bubble,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	globals,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	outro_and_destroy_block,
	safe_not_equal,
	space,
	toggle_class,
	transition_in,
	transition_out,
	update_keyed_each
} from "svelte/internal";

const { document: document_1 } = globals;
import { createEventDispatcher, onMount } from "svelte";
import SpotListButton from "./SpotListButton.svelte";
import { mapEventProvider } from "../mapEventProvider.js";
import { SpotListState } from "../spotListState.js";
import ScrollTouchHandler from "../scrollTouchHandler.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-10zazpv-style";
	style.textContent = "img.svelte-10zazpv{position:absolute;right:1rem;top:1rem;width:2rem;height:2rem}.flex-it.svelte-10zazpv{display:flex;justify-content:flex-end}.list.svelte-10zazpv{margin-top:4rem}";
	append(document_1.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	return child_ctx;
}

// (88:4) {#if spots}
function create_if_block(ctx) {
	let div;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let current;
	let each_value = /*spots*/ ctx[0];
	const get_key = ctx => /*spot*/ ctx[8].id;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "list svelte-10zazpv");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*spots*/ 1) {
				const each_value = /*spots*/ ctx[0];
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div, outro_and_destroy_block, create_each_block, null, get_each_context);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}
		}
	};
}

// (90:12) {#each spots as spot(spot.id)}
function create_each_block(key_1, ctx) {
	let first;
	let spotlistbutton;
	let current;
	spotlistbutton = new SpotListButton({ props: { spot: /*spot*/ ctx[8] } });
	spotlistbutton.$on("selectionRequest", /*selectionRequest_handler*/ ctx[4]);

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(spotlistbutton.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(spotlistbutton, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const spotlistbutton_changes = {};
			if (dirty & /*spots*/ 1) spotlistbutton_changes.spot = /*spot*/ ctx[8];
			spotlistbutton.$set(spotlistbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(spotlistbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(spotlistbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(first);
			destroy_component(spotlistbutton, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let img;
	let img_src_value;
	let t;
	let current;
	let mounted;
	let dispose;
	let if_block = /*spots*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			img = element("img");
			t = space();
			if (if_block) if_block.c();
			if (img.src !== (img_src_value = "minimize.svg")) attr(img, "src", img_src_value);
			attr(img, "alt", "minimize button");
			attr(img, "class", "svelte-10zazpv");
			attr(div0, "class", "flex-it svelte-10zazpv");
			attr(div1, "class", "spotlist noselect");
			toggle_class(div1, "spotlist-closed", /*spotListState*/ ctx[1] == SpotListState.CLOSED);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, img);
			append(div1, t);
			if (if_block) if_block.m(div1, null);
			/*div1_binding*/ ctx[5](div1);
			current = true;

			if (!mounted) {
				dispose = listen(img, "click", /*onCloseRequest*/ ctx[3]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*spots*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*spots*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div1, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			if (dirty & /*spotListState, SpotListState*/ 2) {
				toggle_class(div1, "spotlist-closed", /*spotListState*/ ctx[1] == SpotListState.CLOSED);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block) if_block.d();
			/*div1_binding*/ ctx[5](null);
			mounted = false;
			dispose();
		}
	};
}

const buttonBaseIDName = "spotListButton_";

function instance($$self, $$props, $$invalidate) {
	let { spots = [] } = $$props;
	let { spotListState = SpotListState.CLOSED } = $$props;
	let container;
	const dispatchEvent = createEventDispatcher();

	function onMapClick(e) {
		if (e.type == "mousedown" || e.type == "touchstart") {
			dispatchEvent("closeRequest", {});
		}
	}

	onMount(() => {
		mapEventProvider.addListener(onMapClick);
		let scrollHandler = new ScrollTouchHandler(container);
		scrollHandler.addListeners();

		return () => {
			mapEventProvider.removeListener(onMapClick);
			scrollHandler.stopListeners();
		};
	});

	function onCloseRequest() {
		dispatchEvent("closeRequest", {});
	}

	function selectionRequest_handler(event) {
		bubble($$self, event);
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			container = $$value;
			$$invalidate(2, container);
		});
	}

	$$self.$set = $$props => {
		if ("spots" in $$props) $$invalidate(0, spots = $$props.spots);
		if ("spotListState" in $$props) $$invalidate(1, spotListState = $$props.spotListState);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*spotListState, spots*/ 3) {
			$: {
				// ensure selected spot button is visible in scroll region
				if (spotListState == SpotListState.OPEN) {
					const selectedSpot = spots.find(s => s.selected);

					if (selectedSpot) {
						const selectedButton = document.querySelector(".spotlist .button-selected");

						if (selectedButton) {
							const totalHeight = selectedButton.offsetTop;

							// console.log("totalHeight", totalHeight);
							const list = document.querySelector(".spotlist");

							list.scrollTop = totalHeight;
						} // console.log("button height", selectedButton.clientHeight);
						// console.log(totalHeight, desiredScrollTop, list.scrollTop, list.scrollHeight);
					}
				}
			}
		}
	};

	return [
		spots,
		spotListState,
		container,
		onCloseRequest,
		selectionRequest_handler,
		div1_binding
	];
}

class SpotList extends SvelteComponent {
	constructor(options) {
		super();
		if (!document_1.getElementById("svelte-10zazpv-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { spots: 0, spotListState: 1 });
	}
}

export default SpotList;