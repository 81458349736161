/* js/components/presentationSlides/SlideTwo.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

function create_fragment(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			if (img.src !== (img_src_value = "onAirDesktop/OnAirDesktop-02.svg")) attr(img, "src", img_src_value);
			attr(img, "alt", "instruction image");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

class SlideTwo extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default SlideTwo;