/* js/components/VideoModal.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	create_bidirectional_transition,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal
} from "svelte/internal";

import { createEventDispatcher } from "svelte";
import { fade } from "svelte/transition";

function add_css() {
	var style = element("style");
	style.id = "svelte-17eim3g-style";
	style.textContent = "div.svelte-17eim3g{position:absolute;z-index:3001;width:100%;height:100%;background-color:white;display:flex;justify-content:center;align-items:center}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div;
	let div_transition;
	let current;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			div.innerHTML = `<iframe src="https://player.vimeo.com/video/449237187" width="640" height="360" frameborder="0" title="video presentation" allow="autoplay; fullscreen" allowfullscreen=""></iframe>`;
			attr(div, "class", "svelte-17eim3g");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(div, "click", /*onModalClick*/ ctx[0]);
				mounted = true;
			}
		},
		p: noop,
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (!div_transition) div_transition = create_bidirectional_transition(div, fade, { duration: 500 }, true);
				div_transition.run(1);
			});

			current = true;
		},
		o(local) {
			if (!div_transition) div_transition = create_bidirectional_transition(div, fade, { duration: 500 }, false);
			div_transition.run(0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (detaching && div_transition) div_transition.end();
			mounted = false;
			dispose();
		}
	};
}

function instance($$self) {
	const dispatchEvent = createEventDispatcher();

	function onModalClick() {
		dispatchEvent("closeRequest", {});
	}

	return [onModalClick];
}

class VideoModal extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-17eim3g-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default VideoModal;