
function lerp(start, end, time) {
    return start * (1 - time) + end * time;
}


export default class LocationTween {
    // duration expressed in millis
    constructor(from, to, duration, stepCB, completeCB) {
        this.from = from.slice();
        this.to = to.slice();
        this.duration = duration;
        this.tweening = false;
        this.stepCB = stepCB;
        this.completeCB = completeCB;
    }

    get isTweening(){
        return this.tweening;
    }

    start() {
        if (this.tweening) return;
        this.start = performance.now();
        this.frameId = window.requestAnimationFrame(this.step.bind(this));
        this.tweening = true;
    }

    stop() {
        window.cancelAnimationFrame(this.frameId);
        this.tweening = false;
    }

    step(timestamp) {
        if (this.start === undefined) {
            this.start = timestamp;
        }
        const elapsed = timestamp - this.start;

        const t = elapsed / this.duration;

        const x = lerp(this.from[0], this.to[0], t);
        const y = lerp(this.from[1], this.to[1], t);
        const next = [x, y];
        // console.log(next);

        if (this.stepCB) {
            this.stepCB(next);
        }

        if (elapsed < this.duration) { 
            this.frameId = window.requestAnimationFrame(this.step.bind(this));
        }else{
            this.completeCB(this.to);
        }
    }
}