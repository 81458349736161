/* js/components/mainMenu/MainMenu_contact.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let h1;
	let t1;
	let br0;
	let br1;
	let t2;
	let div0;
	let t13;
	let p;
	let t14;
	let div1;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Contact";
			t1 = space();
			br0 = element("br");
			br1 = element("br");
			t2 = space();
			div0 = element("div");

			div0.innerHTML = `
        teem studio<br><br>
        Dimitri Delcourt<br>
        David Hodgetts<br> 
        <br>
        Rue de la Coulouvrenière 8<br>
        1204 Genève<br> 
        <br>
        t  +41 78 843 76 04<br>
        e  <a href="mailto:contact@audiogenese.net">contact@audiogenese.net</a>`;

			t13 = space();
			p = element("p");
			p.innerHTML = `<br><br><br><br><br><br>`;
			t14 = space();
			div1 = element("div");
			div1.innerHTML = `<a href="mailto:contact@audiogenese.net"><button class="button">Contact</button></a>`;
			attr(div0, "class", "text-indent");
			attr(div1, "class", "buttonWrapper");
			set_style(div1, "z-index", "3001");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, br0, anchor);
			insert(target, br1, anchor);
			insert(target, t2, anchor);
			insert(target, div0, anchor);
			insert(target, t13, anchor);
			insert(target, p, anchor);
			insert(target, t14, anchor);
			insert(target, div1, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(br0);
			if (detaching) detach(br1);
			if (detaching) detach(t2);
			if (detaching) detach(div0);
			if (detaching) detach(t13);
			if (detaching) detach(p);
			if (detaching) detach(t14);
			if (detaching) detach(div1);
		}
	};
}

class MainMenu_contact extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default MainMenu_contact;