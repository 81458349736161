/* js/components/mainMenu/MainMenu_remerciements.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let h1;
	let t1;
	let br0;
	let br1;
	let t2;
	let div0;
	let t4;
	let br2;
	let t5;
	let p0;
	let t7;
	let div1;
	let t12;
	let br7;
	let t13;
	let p1;
	let t15;
	let div2;
	let t20;
	let br12;
	let t21;
	let p2;
	let t23;
	let p3;
	let t43;
	let p4;
	let t44;
	let div3;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Remerciements";
			t1 = space();
			br0 = element("br");
			br1 = element("br");
			t2 = space();
			div0 = element("div");
			div0.textContent = "Le projet AudioGenese n'aurait pas vu le jour sans l'aide de nombreuses énergies. Nous tenons à remercier toutes les personnes qui ont contribué à la réalisation d'une manière ou d'une autre.";
			t4 = space();
			br2 = element("br");
			t5 = space();
			p0 = element("p");
			p0.textContent = "Nos collaborateurs surdoués";
			t7 = space();
			div1 = element("div");

			div1.innerHTML = `
        Mika Ventura<br>
        Shiwa Tseng<br>
        Philippe Richard<br>
        Sophie Czich<br>`;

			t12 = space();
			br7 = element("br");
			t13 = space();
			p1 = element("p");
			p1.textContent = "Presse et partenaires";
			t15 = space();
			div2 = element("div");

			div2.innerHTML = `
    AMR — François Tschumy<br>
    Cave 12 — Fernando Sixto<br>
    Radio Vostok — Charles Menger<br>
    RTS Culture — Miruna Coca Cosma<br>`;

			t20 = space();
			br12 = element("br");
			t21 = space();
			p2 = element("p");
			p2.textContent = "Les institutions et lieux";
			t23 = space();
			p3 = element("p");

			p3.innerHTML = `
        Véronique Lombard — Ville de Genève, DCTN<br>
        Eve-Anouk Gruosso — Ville de Genève, DCTN<br>
        Patrizia Gruosso — Ville de Genève, SEP<br>
        Thierry Lin — Canton de Genève, DI<br>
        Christelle Metthez Ville de Genève, DSES<br><br>
        
        Gilles Taramarcaz —  Parc la Grange, SEVE<br>
        Boris Briffaud —  Parc la Grange, SEVE<br><br>
        
        Mme Girardet — Commune de Veyrier, <br>Service de la culture<br>
        Cédric Domenigoni, Commune de Veyrier, <br>police municipale<br><br>
        
        
        Catherine Jeandet — Commune <br>de Pregny-Chambésy<br>
        Geoffrey Aloisy — Chateau de Penthes<br><br>
        
        Michael Gussing — Ville de Genève, SPF<br>
        Catherine Gauthier — Cimetière St-Georges<br>
        Serge Viennaz — Cimetière des Rois<br><br>

        Philippe Constantin — Bains des Pâquis, AUBP<br>
        Andrea Novicov — Théâtre de l’Orangerie<br>`;

			t43 = space();
			p4 = element("p");
			p4.innerHTML = `<br><br><br><br><br><br>`;
			t44 = space();
			div3 = element("div");
			div3.innerHTML = `<a href="mailto:contact@audiogenese.net"><button class="button">Contact</button></a>`;
			attr(h1, "class", "");
			attr(div0, "class", "text-indent");
			attr(p0, "class", "interview_question");
			attr(div1, "class", "text-indent");
			attr(p1, "class", "interview_question");
			attr(div2, "class", "text-indent");
			attr(p2, "class", "interview_question");
			attr(div3, "class", "buttonWrapper");
			set_style(div3, "z-index", "3001");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, br0, anchor);
			insert(target, br1, anchor);
			insert(target, t2, anchor);
			insert(target, div0, anchor);
			insert(target, t4, anchor);
			insert(target, br2, anchor);
			insert(target, t5, anchor);
			insert(target, p0, anchor);
			insert(target, t7, anchor);
			insert(target, div1, anchor);
			insert(target, t12, anchor);
			insert(target, br7, anchor);
			insert(target, t13, anchor);
			insert(target, p1, anchor);
			insert(target, t15, anchor);
			insert(target, div2, anchor);
			insert(target, t20, anchor);
			insert(target, br12, anchor);
			insert(target, t21, anchor);
			insert(target, p2, anchor);
			insert(target, t23, anchor);
			insert(target, p3, anchor);
			insert(target, t43, anchor);
			insert(target, p4, anchor);
			insert(target, t44, anchor);
			insert(target, div3, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(br0);
			if (detaching) detach(br1);
			if (detaching) detach(t2);
			if (detaching) detach(div0);
			if (detaching) detach(t4);
			if (detaching) detach(br2);
			if (detaching) detach(t5);
			if (detaching) detach(p0);
			if (detaching) detach(t7);
			if (detaching) detach(div1);
			if (detaching) detach(t12);
			if (detaching) detach(br7);
			if (detaching) detach(t13);
			if (detaching) detach(p1);
			if (detaching) detach(t15);
			if (detaching) detach(div2);
			if (detaching) detach(t20);
			if (detaching) detach(br12);
			if (detaching) detach(t21);
			if (detaching) detach(p2);
			if (detaching) detach(t23);
			if (detaching) detach(p3);
			if (detaching) detach(t43);
			if (detaching) detach(p4);
			if (detaching) detach(t44);
			if (detaching) detach(div3);
		}
	};
}

class MainMenu_remerciements extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default MainMenu_remerciements;