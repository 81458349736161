/* js/components/MeCenterButton.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	add_render_callback,
	attr,
	create_bidirectional_transition,
	detach,
	element,
	init,
	insert,
	listen,
	safe_not_equal,
	toggle_class
} from "svelte/internal";

import { fade } from "svelte/transition";

function create_fragment(ctx) {
	let div;
	let div_transition;
	let current;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			attr(div, "class", "center-me noselect");
			toggle_class(div, "center-me-active", /*active*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(div, "click", /*onClick*/ ctx[1]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*active*/ 1) {
				toggle_class(div, "center-me-active", /*active*/ ctx[0]);
			}
		},
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (!div_transition) div_transition = create_bidirectional_transition(div, fade, {}, true);
				div_transition.run(1);
			});

			current = true;
		},
		o(local) {
			if (!div_transition) div_transition = create_bidirectional_transition(div, fade, {}, false);
			div_transition.run(0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (detaching && div_transition) div_transition.end();
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { clickHandler } = $$props;
	let { active = false } = $$props;

	function onClick(e) {
		e.stopPropagation();

		if (clickHandler) {
			clickHandler();
		}
	}

	$$self.$set = $$props => {
		if ("clickHandler" in $$props) $$invalidate(2, clickHandler = $$props.clickHandler);
		if ("active" in $$props) $$invalidate(0, active = $$props.active);
	};

	return [active, onClick, clickHandler];
}

class MeCenterButton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { clickHandler: 2, active: 0 });
	}
}

export default MeCenterButton;