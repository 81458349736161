/* js/components/interviews/ArtistInfo1.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let h1;
	let t1;
	let div0;
	let t3;
	let p0;
	let t5;
	let div1;
	let t7;
	let p1;
	let t9;
	let div2;
	let t11;
	let p2;
	let t13;
	let div3;
	let t15;
	let p3;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Béatrice Graf";
			t1 = space();
			div0 = element("div");
			div0.textContent = "Béatrice Graf se définit comme batteure et indique toucher à tous les styles de musique, sauf la musique classique : jazz, rock, contemporain, electro, et musiques du monde. Elle milite également en faveur d'une prise de conscience concernant le changement climatique.";
			t3 = space();
			p0 = element("p");
			p0.textContent = "D’où viens-tu?";
			t5 = space();
			div1 = element("div");
			div1.textContent = "De Genève";
			t7 = space();
			p1 = element("p");
			p1.textContent = "Pourquoi as-tu décidé de faire partie du projet?";
			t9 = space();
			div2 = element("div");
			div2.textContent = "Car je trouve ce projet passionnant.";
			t11 = space();
			p2 = element("p");
			p2.textContent = "Quels sont tes envies pour le futur?";
			t13 = space();
			div3 = element("div");
			div3.textContent = "Une société en transition écologique accélérée.";
			t15 = space();
			p3 = element("p");
			p3.innerHTML = `<br><br><br><br>`;
			attr(div0, "class", "text-indent");
			attr(p0, "class", "interview_question");
			attr(div1, "class", "text-indent");
			attr(p1, "class", "interview_question");
			attr(div2, "class", "text-indent");
			attr(p2, "class", "interview_question");
			attr(div3, "class", "text-indent");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, div0, anchor);
			insert(target, t3, anchor);
			insert(target, p0, anchor);
			insert(target, t5, anchor);
			insert(target, div1, anchor);
			insert(target, t7, anchor);
			insert(target, p1, anchor);
			insert(target, t9, anchor);
			insert(target, div2, anchor);
			insert(target, t11, anchor);
			insert(target, p2, anchor);
			insert(target, t13, anchor);
			insert(target, div3, anchor);
			insert(target, t15, anchor);
			insert(target, p3, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(div0);
			if (detaching) detach(t3);
			if (detaching) detach(p0);
			if (detaching) detach(t5);
			if (detaching) detach(div1);
			if (detaching) detach(t7);
			if (detaching) detach(p1);
			if (detaching) detach(t9);
			if (detaching) detach(div2);
			if (detaching) detach(t11);
			if (detaching) detach(p2);
			if (detaching) detach(t13);
			if (detaching) detach(div3);
			if (detaching) detach(t15);
			if (detaching) detach(p3);
		}
	};
}

class ArtistInfo1 extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default ArtistInfo1;