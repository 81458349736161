/* js/components/PresentationSlideshow.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	check_outros,
	create_component,
	destroy_component,
	detach,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "svelte/internal";

import { onMount } from "svelte";
import SlideOne from "./presentationSlides/SlideOne.svelte";
import SlideTwo from "./presentationSlides/SlideTwo.svelte";
import SlideThree from "./presentationSlides/SlideThree.svelte";
import SlideFour from "./presentationSlides/SlideFour.svelte";
import SlideFive from "./presentationSlides/SlideFive.svelte";

function create_fragment(ctx) {
	let switch_instance;
	let switch_instance_anchor;
	let current;
	var switch_value = /*selectedComponent*/ ctx[0];

	function switch_props(ctx) {
		return {};
	}

	if (switch_value) {
		switch_instance = new switch_value(switch_props(ctx));
	}

	return {
		c() {
			if (switch_instance) create_component(switch_instance.$$.fragment);
			switch_instance_anchor = empty();
		},
		m(target, anchor) {
			if (switch_instance) {
				mount_component(switch_instance, target, anchor);
			}

			insert(target, switch_instance_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (switch_value !== (switch_value = /*selectedComponent*/ ctx[0])) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = new switch_value(switch_props(ctx));
					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, switch_instance_anchor.parentNode, switch_instance_anchor);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				0;
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(switch_instance_anchor);
			if (switch_instance) destroy_component(switch_instance, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { duration = 2000 } = $$props;
	let index = 0;
	let intervalId;

	const data = {
		0: SlideOne,
		1: SlideTwo,
		2: SlideThree,
		3: SlideFour,
		4: SlideFive
	};

	const componentCount = Object.keys(data).length;
	let selectedComponent = data[index];

	onMount(() => {
		intervalId = setInterval(
			() => {
				index = (index + 1) % componentCount;
				$$invalidate(0, selectedComponent = data[index]);
			},
			duration
		);

		return () => clearInterval(intervalId);
	});

	$$self.$set = $$props => {
		if ("duration" in $$props) $$invalidate(1, duration = $$props.duration);
	};

	return [selectedComponent, duration];
}

class PresentationSlideshow extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { duration: 1 });
	}
}

export default PresentationSlideshow;