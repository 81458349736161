/* js/components/SpotMarker.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	binding_callbacks,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	toggle_class
} from "svelte/internal";

import { onMount } from "svelte";
import mapboxgl from "mapbox-gl";
import { getContext, createEventDispatcher } from "svelte";
import { mapbox, key } from "../mapbox.js";
import { genevaCoords } from "../geoUtils.js";
import { createGeoJSONCircle } from "../geoUtils.js";

function create_fragment(ctx) {
	let img;
	let img_src_value;
	let mounted;
	let dispose;

	return {
		c() {
			img = element("img");
			if (img.src !== (img_src_value = "./SpotMarker.svg")) attr(img, "src", img_src_value);
			attr(img, "class", "spot-marker");
			attr(img, "alt", "spot marker");
			toggle_class(img, "spot-marker-selected", /*selected*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, img, anchor);
			/*img_binding*/ ctx[6](img);

			if (!mounted) {
				dispose = listen(img, "click", /*onClick*/ ctx[2]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*selected*/ 1) {
				toggle_class(img, "spot-marker-selected", /*selected*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(img);
			/*img_binding*/ ctx[6](null);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { coords } = $$props;
	let { id } = $$props;
	let { radius } = $$props;
	let { selected = false } = $$props;
	let markerElement;

	// let mounted = false;
	const { getMap } = getContext(key);

	const map = getMap();
	const dispatch = createEventDispatcher();
	const radiusLayerId = `radius_${id}`;

	// $: {
	//     if(mounted){
	//         if(selected){
	//             map.setLayoutProperty(radiusLayerId, 'visibility', 'visible');
	//         }else{
	//             map.setLayoutProperty(radiusLayerId, 'visibility', 'none');
	//         }
	//     } 
	// }
	onMount(() => {
		const options = { element: markerElement, anchor: "center" };
		const myPositionMarker = new mapboxgl.Marker(options).setLngLat(coords).addTo(map);
		const detectionRadiusKM = radius / 1000;
		const displayRadiusKM = detectionRadiusKM / 4; // display radius is intentionnaly much smaller!!
		map.addSource(radiusLayerId, createGeoJSONCircle(coords, displayRadiusKM));

		map.addLayer({
			"id": radiusLayerId,
			"type": "fill",
			"source": radiusLayerId,
			"layout": {},
			"minzoom": 14,
			"visibility": "visible",
			"paint": {
				"fill-color": "white",
				"fill-opacity": 0.35
			}
		});
	}); // mounted = true;

	function onClick() {
		dispatch("requestSelection", { id });
	}

	function img_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			markerElement = $$value;
			$$invalidate(1, markerElement);
		});
	}

	$$self.$set = $$props => {
		if ("coords" in $$props) $$invalidate(3, coords = $$props.coords);
		if ("id" in $$props) $$invalidate(4, id = $$props.id);
		if ("radius" in $$props) $$invalidate(5, radius = $$props.radius);
		if ("selected" in $$props) $$invalidate(0, selected = $$props.selected);
	};

	return [selected, markerElement, onClick, coords, id, radius, img_binding];
}

class SpotMarker extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { coords: 3, id: 4, radius: 5, selected: 0 });
	}
}

export default SpotMarker;