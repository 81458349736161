
import { cubicInOut } from 'svelte/easing';


export function slideFromBelow(node, { delay = 0, duration = 400 }) {
    const opacity = +getComputedStyle(node).opacity;
    const top = parseInt(getComputedStyle(node).top);

    return {
        delay,
        duration,
        css: t => {
            t = cubicInOut(t);
            const val = (1 - t) * 100;
            return `top: ${val}%;opacity:${t * opacity}`;
        }
    };
}