
import { GeoLocationMessage } from './geoLocation';

function createFakeGeoLocationProvider(){
    let listeners = [];

    let map = null;

    function _broadcastCoords (coords) {
        listeners.forEach( l => l(coords));
    }

    let result = {
        addListener: (listener) => listeners.push(listener),
        removeListener: (listener) => {
            const index = listeners.indexOf(listener);
            if(index >= 0){
                listeners.splice(index, 1);
            }
        },
        initMap:(aMap) => {
            if(map == aMap) return;
            map = aMap;
            map.on('click', function(e){
                // console.log(this, e);
                const coords = e.lngLat;
                _broadcastCoords(GeoLocationMessage.newCoords(coords));
            });
            console.log("inited fake geo location map click provider");
        },
        start:() => { 
            // do nothing
        },
        stop: () => {
            // do nothing
        },
        publishCoords:(coords) => {
            _broadcastCoords(GeoLocationMessage.newCoords(coords));
        },
    }

    return result;
}

export const fakeGeoLocationProvider = createFakeGeoLocationProvider();