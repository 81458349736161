/* js/components/interviews/ArtistInfo8.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let h1;
	let t1;
	let div0;
	let t3;
	let p0;
	let t5;
	let div1;
	let t7;
	let p1;
	let t9;
	let div2;
	let t11;
	let p2;
	let t13;
	let div3;
	let t15;
	let p3;
	let t17;
	let div4;
	let t19;
	let p4;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Tobias Preisig";
			t1 = space();
			div0 = element("div");
			div0.textContent = "Tobias Preisig (*1981, Zürich) is a Swiss violin player - composer and improviser - based between Zurich and Berlin. His music explores the intersections of experimental, ambient and a wide range of electronica, as well as neo-contemporary. Performing in unconventional venues plays an important role in his project. He also tours in Europe and internationally in festivals such as London Jazz, Mama Paris, Reeperbahn Hamburg, Montreux Jazz, Jarasum South Korea and Tokyo Jazz Japan. In 2019, his solo album Diver was released on Quiet Love Records. He is part of Egopusher (with Alessandro Giannelli) and Levitation (with Stefan Rusconi on church organ), and recently he’s been composing music for films.";
			t3 = space();
			p0 = element("p");
			p0.textContent = "D’où viens-tu?";
			t5 = space();
			div1 = element("div");
			div1.textContent = "Zürich";
			t7 = space();
			p1 = element("p");
			p1.textContent = "Pourquoi as-tu décidé de faire partie du projet?";
			t9 = space();
			div2 = element("div");
			div2.textContent = "Playing in different, unconventional places, became an important aspect of my solo project. Thus the idea of associating my music to a specific urban and public space, definitely seduced me. I also like the accesibility it creates. Knowing that my music reaches an active audience in a different way, opens a new sort of exchange.";
			t11 = space();
			p2 = element("p");
			p2.textContent = "Pourquoi as-tu choisis ce lieu pour l’écoute de ta musique?";
			t13 = space();
			div3 = element("div");
			div3.textContent = "With the pulsating City in your back, you find yourself on a peacefull spot. In the middle of a network of roads, this wonderful opening to the water has an inspiring tension. My music is all about diving into the unknown, free up from the world behind and let yourself be driven by your inner instincts. ";
			t15 = space();
			p3 = element("p");
			p3.textContent = "Quels sont tes envies pour le futur?";
			t17 = space();
			div4 = element("div");
			div4.textContent = "My Solo work has grown these last years and with this album Diver, i realized the infinite possibilites to explore new spaces. Once you start to dive in, you want to go deeper and deeper. \n          The crisis brought allot of new reflections in our field. We‘ll have to embrace them to experiment further in term of content but also formats. ";
			t19 = space();
			p4 = element("p");
			p4.innerHTML = `<br><br><br><br><br><br><br><br>`;
			attr(div0, "class", "text-indent");
			attr(p0, "class", "interview_question");
			attr(div1, "class", "text-indent");
			attr(p1, "class", "interview_question");
			attr(div2, "class", "text-indent");
			attr(p2, "class", "interview_question");
			attr(div3, "class", "text-indent");
			attr(p3, "class", "interview_question");
			attr(div4, "class", "text-indent");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, div0, anchor);
			insert(target, t3, anchor);
			insert(target, p0, anchor);
			insert(target, t5, anchor);
			insert(target, div1, anchor);
			insert(target, t7, anchor);
			insert(target, p1, anchor);
			insert(target, t9, anchor);
			insert(target, div2, anchor);
			insert(target, t11, anchor);
			insert(target, p2, anchor);
			insert(target, t13, anchor);
			insert(target, div3, anchor);
			insert(target, t15, anchor);
			insert(target, p3, anchor);
			insert(target, t17, anchor);
			insert(target, div4, anchor);
			insert(target, t19, anchor);
			insert(target, p4, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(div0);
			if (detaching) detach(t3);
			if (detaching) detach(p0);
			if (detaching) detach(t5);
			if (detaching) detach(div1);
			if (detaching) detach(t7);
			if (detaching) detach(p1);
			if (detaching) detach(t9);
			if (detaching) detach(div2);
			if (detaching) detach(t11);
			if (detaching) detach(p2);
			if (detaching) detach(t13);
			if (detaching) detach(div3);
			if (detaching) detach(t15);
			if (detaching) detach(p3);
			if (detaching) detach(t17);
			if (detaching) detach(div4);
			if (detaching) detach(t19);
			if (detaching) detach(p4);
		}
	};
}

class ArtistInfo8 extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default ArtistInfo8;