/* js/components/interviews/ArtistInfo4.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let h1;
	let t1;
	let div0;
	let t4;
	let p0;
	let t6;
	let div1;
	let t8;
	let p1;
	let t10;
	let div2;
	let t12;
	let p2;
	let t14;
	let div3;
	let t16;
	let p3;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Evelinn Trouble";
			t1 = space();
			div0 = element("div");

			div0.innerHTML = `
            Evelinn Trouble, fille d‘une chanteuse de jazz suédoise et née à Zurich, enregistre seule son premier album à l‘âge de 17 ans et s‘inscrit rapidement au sommet de la scène musicale suisse. Mais Trouble en veut plus: elle réalise ses propres vidéoclips et ceux d‘autres groupes, elle joue la Salomé sanglante dans l‘opéra du même nom, elle écrit de la musique pour le cinéma et le théâtre et parcourt le monde avec une pièce de Thom Luz jouant le rôle d‘un chevalier frappé par la foudre. <br><br>Son talent créatif avec le désir inné d‘expérimenter au-delà de frontières nationales, sentimentales et de genres s‘oppose à toute catégorisation – Trouble remporte en 2018 le Prix suisse de la musique en tant que lauréate la plus jeune dans l‘histoire de ce prix. 
        `;

			t4 = space();
			p0 = element("p");
			p0.textContent = "D’où viens-tu?";
			t6 = space();
			div1 = element("div");
			div1.textContent = "Zürich";
			t8 = space();
			p1 = element("p");
			p1.textContent = "Pourquoi as-tu décidé de faire partie du projet?";
			t10 = space();
			div2 = element("div");
			div2.textContent = "I have very little opportunities to earn money and this was a paid job so yes. Also I like the idea of new people discovering my music in a landscape.";
			t12 = space();
			p2 = element("p");
			p2.textContent = "Quels sont tes envies pour le futur?";
			t14 = space();
			div3 = element("div");
			div3.textContent = "I want to live in a society that does not need to categorize, oppress & exploit in order to function & I want to apply this freedom & generosity in my most intimate surroundings all day every day.";
			t16 = space();
			p3 = element("p");
			p3.innerHTML = `<br><br><br><br>`;
			attr(div0, "class", "text-indent");
			attr(p0, "class", "interview_question");
			attr(div1, "class", "text-indent");
			attr(p1, "class", "interview_question");
			attr(div2, "class", "text-indent");
			attr(p2, "class", "interview_question");
			attr(div3, "class", "text-indent");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, div0, anchor);
			insert(target, t4, anchor);
			insert(target, p0, anchor);
			insert(target, t6, anchor);
			insert(target, div1, anchor);
			insert(target, t8, anchor);
			insert(target, p1, anchor);
			insert(target, t10, anchor);
			insert(target, div2, anchor);
			insert(target, t12, anchor);
			insert(target, p2, anchor);
			insert(target, t14, anchor);
			insert(target, div3, anchor);
			insert(target, t16, anchor);
			insert(target, p3, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(div0);
			if (detaching) detach(t4);
			if (detaching) detach(p0);
			if (detaching) detach(t6);
			if (detaching) detach(div1);
			if (detaching) detach(t8);
			if (detaching) detach(p1);
			if (detaching) detach(t10);
			if (detaching) detach(div2);
			if (detaching) detach(t12);
			if (detaching) detach(p2);
			if (detaching) detach(t14);
			if (detaching) detach(div3);
			if (detaching) detach(t16);
			if (detaching) detach(p3);
		}
	};
}

class ArtistInfo4 extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default ArtistInfo4;