/* js/components/Intro.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	create_in_transition,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Logo from "./Logo.svelte";
import { introStateStore } from "../introStateStore.js";
import SplashScreen from "./SplashScreen.svelte";
import InfoView from "./InfoView.svelte";

// import MainMenu from './MainMenu.svelte';
import { fly, fade } from "svelte/transition";

import { Config } from "../config.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-xzxa4q-style";
	style.textContent = ".black.svelte-xzxa4q{background-color:black;color:white}.info.svelte-xzxa4q{width:calc((100vw * 0.25) - 1rem);display:inline-block}.go.svelte-xzxa4q{width:calc((100vw * 0.75) - 1.5rem);position:absolute;right:0;display:inline-block}.translations.svelte-xzxa4q{margin-top:2rem;margin-bottom:3rem;line-height:1.25rem}";
	append(document.head, style);
}

// (80:0) {#if showInfoView}
function create_if_block_3(ctx) {
	let infoview;
	let current;
	infoview = new InfoView({ props: { id: "info" } });
	infoview.$on("closeRequest", /*onInfoViewRequestsClosing*/ ctx[7]);

	return {
		c() {
			create_component(infoview.$$.fragment);
		},
		m(target, anchor) {
			mount_component(infoview, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(infoview.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(infoview.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(infoview, detaching);
		}
	};
}

// (117:90) 
function create_if_block_2(ctx) {
	let logo;
	let t0;
	let div3;
	let div1;
	let t9;
	let div2;
	let button;
	let div3_intro;
	let current;
	let mounted;
	let dispose;
	logo = new Logo({});

	return {
		c() {
			create_component(logo.$$.fragment);
			t0 = space();
			div3 = element("div");
			div1 = element("div");

			div1.innerHTML = `<br> 
            <h2 class="indent">Veuillez s’il-vous plaît accepter l’activation du GPS. Aucune données de localisation ne quittera votre téléphone.</h2> 
            <div class="text-indent"><p><br>Please accept GPS activation. We do not store any data.</p> 
                <p>Bitte akzeptieren Sie die GPS-Aktivierung. Wir speichern keine Daten.</p> 
                <p>Si prega di accettare l’attivazione del GPS. Non memorizziamo alcun dato.</p></div>`;

			t9 = space();
			div2 = element("div");
			button = element("button");
			button.textContent = "GO";
			attr(div1, "class", "infoWrapper");
			attr(button, "class", "button");
			attr(div2, "class", "buttonWrapper");
			attr(div3, "class", "container noselect");
		},
		m(target, anchor) {
			mount_component(logo, target, anchor);
			insert(target, t0, anchor);
			insert(target, div3, anchor);
			append(div3, div1);
			append(div3, t9);
			append(div3, div2);
			append(div2, button);
			current = true;

			if (!mounted) {
				dispose = listen(button, "click", /*gpsInfoPageGoHandler*/ ctx[5]);
				mounted = true;
			}
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(logo.$$.fragment, local);

			if (!div3_intro) {
				add_render_callback(() => {
					div3_intro = create_in_transition(div3, fade, { duration: 800, delay: 820 });
					div3_intro.start();
				});
			}

			current = true;
		},
		o(local) {
			transition_out(logo.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(logo, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div3);
			mounted = false;
			dispose();
		}
	};
}

// (88:27) 
function create_if_block_1(ctx) {
	let logo;
	let t0;
	let div6;
	let div4;
	let t21;
	let div5;
	let button0;
	let t23;
	let button1;
	let current;
	let mounted;
	let dispose;
	logo = new Logo({});

	return {
		c() {
			create_component(logo.$$.fragment);
			t0 = space();
			div6 = element("div");
			div4 = element("div");

			div4.innerHTML = `<div class="translations svelte-xzxa4q"><div class="column-left">Virtual sound<br>installations<br>in the public<br>space</div> 
                <div class="column-center">Virtuelle<br>Klang-<br>installationen<br>im öffentlichen<br>Raum</div> 
                <div class="column-right">Installazioni<br>sonore virtuali<br>nello spazio<br>pubblico</div></div> 
            
            <h1 class="indent">Installations sonores virtuelles dans l&#39;espace public</h1>  
            <h2>Genève<br>15.8 — 25.10.2020</h2>`;

			t21 = space();
			div5 = element("div");
			button0 = element("button");
			button0.textContent = "info";
			t23 = space();
			button1 = element("button");
			button1.textContent = "→ → →";
			attr(div4, "class", "infoWrapper");
			attr(button0, "class", "button info black svelte-xzxa4q");
			attr(button1, "class", "button go svelte-xzxa4q");
			attr(div5, "class", "buttonWrapper");
			attr(div6, "class", "container noselect");
		},
		m(target, anchor) {
			mount_component(logo, target, anchor);
			insert(target, t0, anchor);
			insert(target, div6, anchor);
			append(div6, div4);
			append(div6, t21);
			append(div6, div5);
			append(div5, button0);
			append(div5, t23);
			append(div5, button1);
			current = true;

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*onInfoClick*/ ctx[6]),
					listen(button1, "click", /*homeGoHandler*/ ctx[4])
				];

				mounted = true;
			}
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(logo.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(logo.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(logo, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div6);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (84:0) {#if currentPage == 0}
function create_if_block(ctx) {
	let splashscreen;
	let current;
	splashscreen = new SplashScreen({});
	splashscreen.$on("complete", /*onSplashScreenComplete*/ ctx[3]);

	return {
		c() {
			create_component(splashscreen.$$.fragment);
		},
		m(target, anchor) {
			mount_component(splashscreen, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(splashscreen.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(splashscreen.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(splashscreen, detaching);
		}
	};
}

function create_fragment(ctx) {
	let t;
	let current_block_type_index;
	let if_block1;
	let if_block1_anchor;
	let current;
	let if_block0 = /*showInfoView*/ ctx[1] && create_if_block_3(ctx);
	const if_block_creators = [create_if_block, create_if_block_1, create_if_block_2];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*currentPage*/ ctx[0] == 0) return 0;
		if (/*currentPage*/ ctx[0] == 1) return 1;
		if (Config.alwaysShowGpsWarning || !/*$introStateStore*/ ctx[2].geoLocationInfoPageCompleted) return 2;
		return -1;
	}

	if (~(current_block_type_index = select_block_type(ctx, -1))) {
		if_block1 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	}

	return {
		c() {
			if (if_block0) if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t, anchor);

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].m(target, anchor);
			}

			insert(target, if_block1_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*showInfoView*/ ctx[1]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*showInfoView*/ 2) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(t.parentNode, t);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if (~current_block_type_index) {
					if_blocks[current_block_type_index].p(ctx, dirty);
				}
			} else {
				if (if_block1) {
					group_outros();

					transition_out(if_blocks[previous_block_index], 1, 1, () => {
						if_blocks[previous_block_index] = null;
					});

					check_outros();
				}

				if (~current_block_type_index) {
					if_block1 = if_blocks[current_block_type_index];

					if (!if_block1) {
						if_block1 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
						if_block1.c();
					}

					transition_in(if_block1, 1);
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				} else {
					if_block1 = null;
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t);

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].d(detaching);
			}

			if (detaching) detach(if_block1_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $introStateStore;
	component_subscribe($$self, introStateStore, $$value => $$invalidate(2, $introStateStore = $$value));
	let currentPage = 0;
	let showInfoView = false;

	function onSplashScreenComplete() {
		$$invalidate(0, currentPage = 1);
	}

	function homeGoHandler() {
		if (Config.alwaysShowGpsWarning) {
			$$invalidate(0, currentPage = 2);
			return;
		}

		if ($introStateStore.geoLocationInfoPageCompleted) {
			introStateStore.setIntroModuleComplete();
		} else {
			$$invalidate(0, currentPage = 2);
		}
	}

	function gpsInfoPageGoHandler() {
		introStateStore.setGeoLocationInfoPageComplete();
		introStateStore.setIntroModuleComplete();
	}

	function onInfoClick() {
		$$invalidate(1, showInfoView = true);
	}

	function onInfoViewRequestsClosing() {
		$$invalidate(1, showInfoView = false);
	}

	return [
		currentPage,
		showInfoView,
		$introStateStore,
		onSplashScreenComplete,
		homeGoHandler,
		gpsInfoPageGoHandler,
		onInfoClick,
		onInfoViewRequestsClosing
	];
}

class Intro extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-xzxa4q-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Intro;