/* js/components/SpotListButton.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	space,
	text,
	toggle_class
} from "svelte/internal";

import { createEventDispatcher } from "svelte";

function create_fragment(ctx) {
	let div2;
	let div0;
	let t0_value = /*spot*/ ctx[0].name + "";
	let t0;
	let t1;
	let div1;
	let t2_value = /*spot*/ ctx[0].artist + "";
	let t2;
	let mounted;
	let dispose;

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			div1 = element("div");
			t2 = text(t2_value);
			attr(div0, "class", "textCenter noselect");
			attr(div1, "class", "small-size noselect");
			attr(div2, "class", "button");
			toggle_class(div2, "button-selected", /*spot*/ ctx[0].selected);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, t0);
			append(div2, t1);
			append(div2, div1);
			append(div1, t2);

			if (!mounted) {
				dispose = listen(div2, "click", /*onClick*/ ctx[1]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*spot*/ 1 && t0_value !== (t0_value = /*spot*/ ctx[0].name + "")) set_data(t0, t0_value);
			if (dirty & /*spot*/ 1 && t2_value !== (t2_value = /*spot*/ ctx[0].artist + "")) set_data(t2, t2_value);

			if (dirty & /*spot*/ 1) {
				toggle_class(div2, "button-selected", /*spot*/ ctx[0].selected);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { spot } = $$props;
	const dispatch = createEventDispatcher();

	function onClick(e) {
		if (spot.selected) {
			return;
		}

		dispatch("selectionRequest", { id: spot.id });
	}

	$$self.$set = $$props => {
		if ("spot" in $$props) $$invalidate(0, spot = $$props.spot);
	};

	return [spot, onClick];
}

class SpotListButton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { spot: 0 });
	}
}

export default SpotListButton;