/* js/components/interviews/ArtistInfo5.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let h1;
	let t1;
	let div0;
	let t3;
	let p0;
	let t5;
	let div1;
	let t7;
	let p1;
	let t9;
	let div2;
	let t11;
	let p2;
	let t13;
	let div3;
	let t15;
	let p3;
	let t17;
	let div4;
	let t19;
	let p4;
	let t21;
	let div5;
	let t23;
	let p5;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Julie Semoroz";
			t1 = space();
			div0 = element("div");
			div0.textContent = "Julie Semoroz est chanteuse, artiste sonore et directrice de projets artistiques. Elle sculpte le son à l’aide de plusieurs sources telles que du field recordings, des micros en direct ainsi que sa voix avec des éléments software et hardware. Elle propose des pièces sonores comme des voyages intérieurs dans l’inconscient où l’on pénètre des zones d’ombres. Son travail interroge le rapport de l’individu au temps mécanique et organique dans ses pratiques corporelles et questionne la société postindustrielle consumériste et les nouvelles technologies. Dans une recherche d’écologie au sens de «l’habitat», Julie Semoroz pose la question de comment habiter nos corps et nos vies dans la société.";
			t3 = space();
			p0 = element("p");
			p0.textContent = "Quel est ton nom d’artiste?";
			t5 = space();
			div1 = element("div");
			div1.textContent = "JMO";
			t7 = space();
			p1 = element("p");
			p1.textContent = "D’où viens-tu?";
			t9 = space();
			div2 = element("div");
			div2.textContent = "Je vis et travaille à Genève. J’ai grandi dans le canton de Vaud en Suisse.";
			t11 = space();
			p2 = element("p");
			p2.textContent = "Pourquoi as-tu décidé de faire partie du projet?";
			t13 = space();
			div3 = element("div");
			div3.textContent = "J’aime bien les cartographies et j’ai bien envie de découvrir le projet en me baladant dans Genève en découvrant les lieux d’autres artistes. Partager mon univers sonore en plein air dans un lieu qui est particulier pour moi me semblait une belle proposition pour le public.";
			t15 = space();
			p3 = element("p");
			p3.textContent = "Pourquoi as-tu choisis ce lieu pour l’écoute de ta musique?";
			t17 = space();
			div4 = element("div");
			div4.textContent = "Je vais m’asseoir dans ce lieu pour faire le vide, pour me retrouver seule. On y trouve une vue assez dégagée sur les montagnes et souvent il n’y a pas grand monde en été à la fin du parc. C’est aussi là que je vais nager, que j’ai partagé des pics-nice, des anniversaires, des discussions et des secrets. L’eau a un lien fort avec la musique que je partage dans AudioGenese.";
			t19 = space();
			p4 = element("p");
			p4.textContent = "Quels sont tes envies pour le futur?";
			t21 = space();
			div5 = element("div");
			div5.textContent = "Créer toujours plus de musique. Retourner au Chili dans le désert d’Atacama et plonger sur la côte près des lions de mers et des baleines d’où proviennent les enregistrements sous marins de la track «Baleina» que j’ai mis en écoute pour AudioGenese. Couper le wifi et le téléphone et y passer 6 mois.";
			t23 = space();
			p5 = element("p");
			p5.innerHTML = `<br><br><br><br>`;
			attr(div0, "class", "text-indent");
			attr(p0, "class", "interview_question");
			attr(div1, "class", "text-indent");
			attr(p1, "class", "interview_question");
			attr(div2, "class", "text-indent");
			attr(p2, "class", "interview_question");
			attr(div3, "class", "text-indent");
			attr(p3, "class", "interview_question");
			attr(div4, "class", "text-indent");
			attr(p4, "class", "interview_question");
			attr(div5, "class", "text-indent");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, div0, anchor);
			insert(target, t3, anchor);
			insert(target, p0, anchor);
			insert(target, t5, anchor);
			insert(target, div1, anchor);
			insert(target, t7, anchor);
			insert(target, p1, anchor);
			insert(target, t9, anchor);
			insert(target, div2, anchor);
			insert(target, t11, anchor);
			insert(target, p2, anchor);
			insert(target, t13, anchor);
			insert(target, div3, anchor);
			insert(target, t15, anchor);
			insert(target, p3, anchor);
			insert(target, t17, anchor);
			insert(target, div4, anchor);
			insert(target, t19, anchor);
			insert(target, p4, anchor);
			insert(target, t21, anchor);
			insert(target, div5, anchor);
			insert(target, t23, anchor);
			insert(target, p5, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(div0);
			if (detaching) detach(t3);
			if (detaching) detach(p0);
			if (detaching) detach(t5);
			if (detaching) detach(div1);
			if (detaching) detach(t7);
			if (detaching) detach(p1);
			if (detaching) detach(t9);
			if (detaching) detach(div2);
			if (detaching) detach(t11);
			if (detaching) detach(p2);
			if (detaching) detach(t13);
			if (detaching) detach(div3);
			if (detaching) detach(t15);
			if (detaching) detach(p3);
			if (detaching) detach(t17);
			if (detaching) detach(div4);
			if (detaching) detach(t19);
			if (detaching) detach(p4);
			if (detaching) detach(t21);
			if (detaching) detach(div5);
			if (detaching) detach(t23);
			if (detaching) detach(p5);
		}
	};
}

class ArtistInfo5 extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default ArtistInfo5;