import SimplePhysix from './simplePhysix';

class MoveCandidate{
    constructor(identifier, startY){
        this.identifier = identifier;
        this.clientY = startY;
    }
} 

export default class ScrollTouchHandler
{
    constructor(domContainer){
        this.domContainer = domContainer;
        this.touchListIdentifiers = [];
        this.scrollTouchCandidate = null;
        this.inertiaHandler = new SimplePhysix(this.handleInertia.bind(this));
        this.intervalId = -1;
    }

    _setupScrollTouchCandidate(touch){
        this.scrollTouchCandidate = new MoveCandidate(touch.identifier, touch.clientY);
        // console.log("added scrollTouchCandidate", this.scrollTouchCandidate);
    }

    _touchListIdentifiersContainsId(identifier){
        return this.touchListIdentifiers.findIndex(id => id === identifier) >= 0;

    }
    /////////////////////////
    // listeners
    _onTouchStart(e){
        for(let i = 0; i < e.changedTouches.length; i++){
            this.touchListIdentifiers.push(e.changedTouches.item(i).identifier);
        }

        if(!this.scrollTouchCandidate){
            this._setupScrollTouchCandidate(e.changedTouches.item(0));
            this.intervalId = setInterval(this.updateAcceleration.bind(this), 50);
        }

        // console.log("added ", this.touchListIdentifiers);
        this.inertiaHandler.stop();
    }

    updateAcceleration(){
        // console.log("add zero");
        // add zero acceleration else we always have inertia
        this.inertiaHandler.addAcceleration(0);
    }

    
    _onTouchEnd(e){
        for(let i = 0; i < e.changedTouches.length; i++){
            const touch = e.changedTouches.item(i);
            const id = touch.identifier;
            const index = this.touchListIdentifiers.findIndex((identifier) => identifier === id);
            if(index >= 0){
                this.touchListIdentifiers.splice(index, 1);
            }
        }
        // console.log("removed", this.touchListIdentifiers);

        if(this.scrollTouchCandidate && !this._touchListIdentifiersContainsId(this.scrollTouchCandidate.identifier)){
            this.scrollTouchCandidate = null;
            clearInterval(this.intervalId);
            // console.log("removed scrollTouchCandidate");
            this.inertiaHandler.start();
        }
    }

    _onTouchCancel(e){
        console.log("cancelled");
        this._onTouchEnd(e);
    }

    _onTouchMove(e){
        if(!this.scrollTouchCandidate)return;

        let touch = null;

        for(let i = 0; i < e.changedTouches.length; i++){
            if(e.changedTouches.item(i).identifier == this.scrollTouchCandidate.identifier){
                touch = e.changedTouches.item(i);
            }
        }

        if(!touch) return;

        const delta = this.scrollTouchCandidate.clientY - touch.clientY;
        this.scrollTouchCandidate.clientY = touch.clientY;
        // console.log("delta", delta);

        this.domContainer.scrollTop += delta;
        this.inertiaHandler.addAcceleration(delta);

    }

    //////////////
    // public api
    addListeners(){
        this.domContainer.addEventListener('touchstart', this._onTouchStart.bind(this));
        this.domContainer.addEventListener('touchend', this._onTouchEnd.bind(this));
        this.domContainer.addEventListener('touchleave', this._onTouchEnd.bind(this));
        this.domContainer.addEventListener('touchcancel', this._onTouchCancel.bind(this));
        this.domContainer.addEventListener('touchmove', this._onTouchMove.bind(this));
    }

    stopListeners(){
        this.domContainer.removeEventListener('touchstart', this._onTouchStart.bind(this));
        this.domContainer.removeEventListener('touchend', this._onTouchEnd.bind(this));
        this.domContainer.removeEventListener('touchleave', this._onTouchEnd.bind(this));
        this.domContainer.removeEventListener('touchcancel', this._onTouchCancel.bind(this));
        this.domContainer.removeEventListener('touchmove', this._onTouchMove.bind(this));
        
        this.inertiaHandler.stop();
        clearInterval(this.intervalId);
    }

    handleInertia(velocity){
        const scrollHeight = this.domContainer.scrollHeight - this.domContainer.clientHeight;
        const nextValue = this.domContainer.scrollTop + velocity;
        // console.log(this.domContainer.scrollTop, velocity);

        if(nextValue < 0){
            // bounce
            this.domContainer.scrollTop = 0;
            this.inertiaHandler.invertVelocity();
        }else if(nextValue > scrollHeight ){
            // bounce
            this.domContainer.scrollTop = scrollHeight;
            this.inertiaHandler.invertVelocity();
        }else{
            this.domContainer.scrollTop = nextValue;
        }
        // console.log(scrollHeight, nextValue, this.domContainer.clientHeight, this.domContainer.scrollTop);
    }
}