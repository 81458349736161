
import { toCoords } from './geoUtils.js';

function newCoords(coords)
{
    return {
        coords: toCoords(coords),
        error:null,
    };
}

function newErrorCoords(code, message){
    return {
        coords:null,
        error: {
            code,
            message,
        },
    };
}

export const GeoLocationMessage =
{
    newCoords,
    newErrorCoords,
}