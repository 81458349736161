
function createProvider(){
    let listeners = [];

    let map = null;

    function _broadcastValue (coords) {
        listeners.forEach( l => l(coords));
    }

    let result = {
        addListener: (listener) => listeners.push(listener),
        removeListener: (listener) => {
            const index = listeners.indexOf(listener);
            if(index >= 0){
                listeners.splice(index, 1);
            }
        },
        provide:(value) => _broadcastValue(value),
    }

    return result;
}

export const flyToProvider = createProvider();

export const orientationChangeProvider = createProvider();