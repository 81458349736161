import { flyToProvider } from './providers.js';
import { computeBounds } from './geoUtils'

export default class MapUserTracker
{
    constructor(){
        this.validGpsCoords = null;
        this.userSelectedSpot = null;
        this.timeoutId = -1;
        this.isTracking = false;
    }

    setGpsCoords(validGpsCoords){
        this.validGpsCoords = validGpsCoords;
    }

    setUserSelectedSpot(userSelectedSpot){
        this.userSelectedSpot = userSelectedSpot;
    }

    fly(){
        if(this.isTracking){
            this.stopTracking();
        }
        this._update(false);
    }

    _update(repeat=true){
        if(this.validGpsCoords && this.userSelectedSpot){
            const flyToOptions = {
                bounds: computeBounds(this.validGpsCoords, this.userSelectedSpot.coords),
            };
            flyToProvider.provide(flyToOptions);
        }else if (this.validGpsCoords){
            const flyToOptions = {
                coordinates: this.validGpsCoords,
            };
            flyToProvider.provide(flyToOptions);
        }

        if(repeat){
            this.timeoutId = setTimeout(this._update.bind(this), 5000);
        } 
    }

    startTracking(){
        if(this.isTracking) return;

        console.log("starts tracking");
        this.isTracking = true;
        this._update();
    }

    stopTracking(){
        if(!this.isTracking) return;
        this.isTracking = false;

        console.log("stops tracking");
        clearTimeout(this.timeoutId);
    }
}