/* js/components/interviews/ArtistInfo2.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let h1;
	let t1;
	let div0;
	let t4;
	let p0;
	let t6;
	let div1;
	let t7;
	let p1;
	let t9;
	let div2;
	let t10;
	let p2;
	let t12;
	let div3;
	let t13;
	let p3;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Daria Mechkat";
			t1 = space();
			div0 = element("div");

			div0.innerHTML = `
          Voilà plus de 20 ans que Daria traîne ses vinyles dans les meilleurs clubs de Suisse et du monde entier. La genevoise a fait ses armes dans la scène underground de la cité de Calvin, où elle fut résidente du légendaire club Weetamix, avant de s’exporter au Watergate de Berlin, où elle fut un des piliers du club durant plusieurs années. <br><br>

          Productrice, Daria a sorti des tracks sur Cadenza, Souvenir Music et Get Physical.
        `;

			t4 = space();
			p0 = element("p");
			p0.textContent = "D’où viens-tu?";
			t6 = space();
			div1 = element("div");
			t7 = space();
			p1 = element("p");
			p1.textContent = "Pourquoi as-tu décidé de faire partie du projet?";
			t9 = space();
			div2 = element("div");
			t10 = space();
			p2 = element("p");
			p2.textContent = "Quels sont tes envies pour le futur?";
			t12 = space();
			div3 = element("div");
			t13 = space();
			p3 = element("p");
			p3.innerHTML = `<br><br><br><br><br><br><br><br>`;
			attr(div0, "class", "text-indent");
			attr(p0, "class", "interview_question");
			attr(div1, "class", "text-indent");
			attr(p1, "class", "interview_question");
			attr(div2, "class", "text-indent");
			attr(p2, "class", "interview_question");
			attr(div3, "class", "text-indent");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, div0, anchor);
			insert(target, t4, anchor);
			insert(target, p0, anchor);
			insert(target, t6, anchor);
			insert(target, div1, anchor);
			insert(target, t7, anchor);
			insert(target, p1, anchor);
			insert(target, t9, anchor);
			insert(target, div2, anchor);
			insert(target, t10, anchor);
			insert(target, p2, anchor);
			insert(target, t12, anchor);
			insert(target, div3, anchor);
			insert(target, t13, anchor);
			insert(target, p3, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(div0);
			if (detaching) detach(t4);
			if (detaching) detach(p0);
			if (detaching) detach(t6);
			if (detaching) detach(div1);
			if (detaching) detach(t7);
			if (detaching) detach(p1);
			if (detaching) detach(t9);
			if (detaching) detach(div2);
			if (detaching) detach(t10);
			if (detaching) detach(p2);
			if (detaching) detach(t12);
			if (detaching) detach(div3);
			if (detaching) detach(t13);
			if (detaching) detach(p3);
		}
	};
}

class ArtistInfo2 extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default ArtistInfo2;