/* js/components/interviews/ArtistInfo6.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let h1;
	let t1;
	let div0;
	let t3;
	let p0;
	let t5;
	let div1;
	let t7;
	let p1;
	let t9;
	let div2;
	let t11;
	let p2;
	let t13;
	let div3;
	let t15;
	let p3;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Byungseo Yoo";
			t1 = space();
			div0 = element("div");
			div0.textContent = "Byungseo Yoo is a visual artist from South Korea. His works has been exhibited internationally, in museums and art institutions such as Seoul Museum of Arts (Seoul), Art Center Ongoing (Tokyo), Ygrec Gallery (Paris),Project Space Public storage (Vancouver), Zk/u (Berlin), Dock11 (Berlin), L’Assaut de la menuiserie (Saint-Etienne).";
			t3 = space();
			p0 = element("p");
			p0.textContent = "Where do you come from?";
			t5 = space();
			div1 = element("div");
			div1.textContent = "Seoul, South Korea";
			t7 = space();
			p1 = element("p");
			p1.textContent = "Why did you decide to be part of the project?";
			t9 = space();
			div2 = element("div");
			div2.textContent = "It’s the cemetery near where we live. We spend quite a lot of time there to find peacefulness.";
			t11 = space();
			p2 = element("p");
			p2.textContent = "What are your desires for the future?";
			t13 = space();
			div3 = element("div");
			div3.textContent = "To be calm and happy.";
			t15 = space();
			p3 = element("p");
			p3.innerHTML = `<br><br><br><br>`;
			attr(div0, "class", "text-indent");
			attr(p0, "class", "interview_question");
			attr(div1, "class", "text-indent");
			attr(p1, "class", "interview_question");
			attr(div2, "class", "text-indent");
			attr(p2, "class", "interview_question");
			attr(div3, "class", "text-indent");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, div0, anchor);
			insert(target, t3, anchor);
			insert(target, p0, anchor);
			insert(target, t5, anchor);
			insert(target, div1, anchor);
			insert(target, t7, anchor);
			insert(target, p1, anchor);
			insert(target, t9, anchor);
			insert(target, div2, anchor);
			insert(target, t11, anchor);
			insert(target, p2, anchor);
			insert(target, t13, anchor);
			insert(target, div3, anchor);
			insert(target, t15, anchor);
			insert(target, p3, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(div0);
			if (detaching) detach(t3);
			if (detaching) detach(p0);
			if (detaching) detach(t5);
			if (detaching) detach(div1);
			if (detaching) detach(t7);
			if (detaching) detach(p1);
			if (detaching) detach(t9);
			if (detaching) detach(div2);
			if (detaching) detach(t11);
			if (detaching) detach(p2);
			if (detaching) detach(t13);
			if (detaching) detach(div3);
			if (detaching) detach(t15);
			if (detaching) detach(p3);
		}
	};
}

class ArtistInfo6 extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default ArtistInfo6;