/* js/components/Hamburger.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	toggle_class
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-1im1ynv-style";
	style.textContent = "button.svelte-1im1ynv.svelte-1im1ynv:focus{outline:none}button.svelte-1im1ynv.svelte-1im1ynv{position:absolute;top:1rem;left:1rem;padding:0;margin:0.1rem;border:none;background-color:transparent;padding:5px}.cursor-pointer.svelte-1im1ynv.svelte-1im1ynv{cursor:pointer}svg.svelte-1im1ynv.svelte-1im1ynv{min-height:24px;transition:transform 0.3s ease-in-out}svg.svelte-1im1ynv line.svelte-1im1ynv{stroke:currentColor;stroke-width:2;transition:transform 0.3s ease-in-out\n\t}button.svelte-1im1ynv.svelte-1im1ynv{z-index:20}.open.svelte-1im1ynv.svelte-1im1ynv{z-index:5001}.open.svelte-1im1ynv svg.svelte-1im1ynv{transform:scale(0.7)\n\t}.open.svelte-1im1ynv #top.svelte-1im1ynv{transform:translate(6px, 0px) rotate(45deg)\n\t}.open.svelte-1im1ynv #middle.svelte-1im1ynv{opacity:0}.open.svelte-1im1ynv #bottom.svelte-1im1ynv{transform:translate(-12px, 9px) rotate(-45deg)\n\t}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.innerHTML = `<svg width="28" height="24" class="svelte-1im1ynv"><line id="top" x1="0" y1="2" x2="32" y2="2" class="svelte-1im1ynv"></line><line id="middle" x1="0" y1="12" x2="32" y2="12" class="svelte-1im1ynv"></line><line id="bottom" x1="0" y1="22" x2="32" y2="22" class="svelte-1im1ynv"></line></svg>`;
			attr(button, "class", "cursor-pointer svelte-1im1ynv");
			toggle_class(button, "open", /*open*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[1]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*open*/ 1) {
				toggle_class(button, "open", /*open*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { open = false } = $$props;
	const click_handler = () => $$invalidate(0, open = !open);

	$$self.$set = $$props => {
		if ("open" in $$props) $$invalidate(0, open = $$props.open);
	};

	return [open, click_handler];
}

class Hamburger extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1im1ynv-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { open: 0 });
	}
}

export default Hamburger;