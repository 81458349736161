/* js/components/InfoView.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	binding_callbacks,
	check_outros,
	create_bidirectional_transition,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import { slideFromBelow } from "../transitions";
import { createEventDispatcher, onMount } from "svelte";
import ArtistInfo1 from "./interviews/ArtistInfo1";
import ArtistInfo2 from "./interviews/ArtistInfo2";
import ArtistInfo3 from "./interviews/ArtistInfo3";
import ArtistInfo4 from "./interviews/ArtistInfo4";
import ArtistInfo5 from "./interviews/ArtistInfo5";
import ArtistInfo6 from "./interviews/ArtistInfo6";
import ArtistInfo7 from "./interviews/ArtistInfo7";
import ArtistInfo8 from "./interviews/ArtistInfo8";
import InfoPage from "./info/InfoPage.svelte";
import MainMenu_apropos from "./mainMenu/MainMenu_apropos.svelte";
import MainMenu_remerciements from "./mainMenu/MainMenu_remerciements.svelte";
import MainMenu_contact from "./mainMenu/MainMenu_contact.svelte";
import MainMenu_feedback from "./mainMenu/MainMenu_feedback.svelte";
import ScrollTouchHandler from "../scrollTouchHandler.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-1islull-style";
	style.textContent = ".info-view.svelte-1islull{position:absolute;top:0%;left:0px;width:100%;height:100%;background:white;z-index:5003;overflow-y:scroll;touch-action:none}.info-view-component.svelte-1islull{margin-top:4rem}.container.svelte-1islull{touch-action:none}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div1;
	let img;
	let img_src_value;
	let t;
	let div0;
	let switch_instance;
	let div1_transition;
	let current;
	let mounted;
	let dispose;
	var switch_value = /*selectedComponent*/ ctx[1];

	function switch_props(ctx) {
		return {};
	}

	if (switch_value) {
		switch_instance = new switch_value(switch_props(ctx));
	}

	return {
		c() {
			div1 = element("div");
			img = element("img");
			t = space();
			div0 = element("div");
			if (switch_instance) create_component(switch_instance.$$.fragment);
			attr(img, "class", "close-button");
			if (img.src !== (img_src_value = "close.svg")) attr(img, "src", img_src_value);
			attr(img, "alt", "close button");
			attr(div0, "class", "container noselect info-view-component svelte-1islull");
			attr(div1, "class", "info-view svelte-1islull");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, img);
			append(div1, t);
			append(div1, div0);

			if (switch_instance) {
				mount_component(switch_instance, div0, null);
			}

			/*div1_binding*/ ctx[4](div1);
			current = true;

			if (!mounted) {
				dispose = listen(img, "click", /*closeRequest*/ ctx[2]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (switch_value !== (switch_value = /*selectedComponent*/ ctx[1])) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = new switch_value(switch_props(ctx));
					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, div0, null);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				0;
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);

			add_render_callback(() => {
				if (!div1_transition) div1_transition = create_bidirectional_transition(div1, slideFromBelow, { duration: 500 }, true);
				div1_transition.run(1);
			});

			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			if (!div1_transition) div1_transition = create_bidirectional_transition(div1, slideFromBelow, { duration: 500 }, false);
			div1_transition.run(0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (switch_instance) destroy_component(switch_instance);
			/*div1_binding*/ ctx[4](null);
			if (detaching && div1_transition) div1_transition.end();
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const dispatchEvent = createEventDispatcher();
	let { id } = $$props;
	let container;

	const components = {
		"info": InfoPage,
		1: ArtistInfo1,
		2: ArtistInfo2,
		3: ArtistInfo3,
		4: ArtistInfo4,
		5: ArtistInfo5,
		6: ArtistInfo6,
		7: ArtistInfo7,
		8: ArtistInfo8,
		100: MainMenu_apropos,
		101: MainMenu_remerciements,
		102: MainMenu_contact,
		103: MainMenu_feedback
	};

	let selectedComponent = components[id];

	onMount(() => {
		let scrollHandler = new ScrollTouchHandler(container);
		scrollHandler.addListeners();

		return () => {
			scrollHandler.stopListeners();
		};
	});

	function closeRequest() {
		dispatchEvent("closeRequest", {});
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			container = $$value;
			$$invalidate(0, container);
		});
	}

	$$self.$set = $$props => {
		if ("id" in $$props) $$invalidate(3, id = $$props.id);
	};

	return [container, selectedComponent, closeRequest, id, div1_binding];
}

class InfoView extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1islull-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { id: 3 });
	}
}

export default InfoView;