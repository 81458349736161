
import { GeoLocationMessage } from './geoLocation';

export default class GeoLocationProvider
{

    static get isSupported(){
        return !!navigator.geolocation;
    }

    constructor()
    {
        this.watchingId = null;
        this.listeners = [];
    }

    addListener(listener){
        this.listeners.push(listener);
        console.log("listener size ", this.listeners.length);
    }

    removeListener(listener){
        const index = this.listeners.indexOf(listener);
        if(index >= 0){
            this.listeners.splice(index, 1);
            console.log("listener size ", this.listeners.length);
        }
    }

    start(){
        if(this.watchingId !== null) return;
        // console.log("started native geo location service");
        const positionOptions = {
            enableHighAccuracy: true
        };
        this.watchingId = navigator.geolocation.watchPosition(this.successHandler.bind(this), this.errorHandler.bind(this), positionOptions);
    }

    stop(){
        navigator.geolocation.clearWatch(this.watchingId);
    }

    successHandler(p) {
        // console.log("new geolocation", p.coords);

        this.listeners.forEach(l => {
            l(GeoLocationMessage.newCoords(p.coords));
        });
    };

    errorHandler(e) {
        // 1 = permission denied
        // 2 = position unavailable
        // 3 = timeout
        console.log("geolocation error", e.code, e.message);
        this.listeners.forEach(l => {
            l(GeoLocationMessage.newErrorCoords(e.code, e.message));
        });
    }
}