/* js/components/GpsPermissionsInfoPage.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Logo from "./Logo.svelte";
import { onMount } from "svelte";
import ScrollTouchHandler from "../scrollTouchHandler.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-1o4k9j1-style";
	style.textContent = "img.svelte-1o4k9j1{width:100%;max-width:414px;margin-bottom:2rem}.scrollable.svelte-1o4k9j1{overflow-y:scroll;height:100vh}.instruction.svelte-1o4k9j1{margin-top:1.5rem;margin-bottom:1.5rem}.last.svelte-1o4k9j1{margin-bottom:5rem}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div1;
	let logo;
	let t0;
	let h20;
	let t2;
	let div0;
	let t6;
	let h21;
	let t8;
	let p1;
	let t10;
	let img0;
	let img0_src_value;
	let t11;
	let p2;
	let t13;
	let img1;
	let img1_src_value;
	let t14;
	let p3;
	let t16;
	let img2;
	let img2_src_value;
	let t17;
	let h22;
	let t19;
	let p4;
	let t21;
	let img3;
	let img3_src_value;
	let t22;
	let p5;
	let t24;
	let p6;
	let current;
	logo = new Logo({});

	return {
		c() {
			div1 = element("div");
			create_component(logo.$$.fragment);
			t0 = space();
			h20 = element("h2");
			h20.textContent = "Accès à la géolocalisation refusé";
			t2 = space();
			div0 = element("div");

			div0.innerHTML = `<p>Vous avez décliné l&#39;accès à la géolocalisation.
            <br><br>
            Si vous voulez participer à cette expérience de scène virtuelle, 
            nous vous invitons à autoriser cette page à accéder aux données de géolocalisation.
            <br><br>
            Aucune donnée de géolocalisation ne quittera votre téléphone.
        </p>`;

			t6 = space();
			h21 = element("h2");
			h21.textContent = "Marche à suivre pour modifier les permissions sur iOS";
			t8 = space();
			p1 = element("p");
			p1.textContent = "1. tapez sur l'icône 'aA' qui se trouve dans la bar d'url.";
			t10 = space();
			img0 = element("img");
			t11 = space();
			p2 = element("p");
			p2.textContent = "2. sélectionnez le bouton des paramètres du site.";
			t13 = space();
			img1 = element("img");
			t14 = space();
			p3 = element("p");
			p3.textContent = "3. donnez l'autorisation pour la géolocalisation.";
			t16 = space();
			img2 = element("img");
			t17 = space();
			h22 = element("h2");
			h22.textContent = "Marche à suivre pour modifier les permissions sur Android";
			t19 = space();
			p4 = element("p");
			p4.textContent = "1. Sur la gauche de l'adresse Web, cliquez sur l'icône qui s'affiche.";
			t21 = space();
			img3 = element("img");
			t22 = space();
			p5 = element("p");
			p5.textContent = "2. Cliquez sur Paramètres du site.";
			t24 = space();
			p6 = element("p");
			p6.textContent = "3. Modifiez un paramètre d'autorisation. \n    Sachez que les modifications apportées sont enregistrées automatiquement.";
			attr(div0, "class", "text-indent");
			attr(p1, "class", "instruction svelte-1o4k9j1");
			if (img0.src !== (img0_src_value = "./gpsPermissions/ios/01.png")) attr(img0, "src", img0_src_value);
			attr(img0, "alt", "iOS explanation 1");
			attr(img0, "class", "svelte-1o4k9j1");
			attr(p2, "class", "instruction svelte-1o4k9j1");
			if (img1.src !== (img1_src_value = "./gpsPermissions/ios/02.jpg")) attr(img1, "src", img1_src_value);
			attr(img1, "alt", "iOS explanation 2");
			attr(img1, "class", "svelte-1o4k9j1");
			attr(p3, "class", "instruction svelte-1o4k9j1");
			attr(img2, "class", "last svelte-1o4k9j1");
			if (img2.src !== (img2_src_value = "./gpsPermissions/ios/03.jpg")) attr(img2, "src", img2_src_value);
			attr(img2, "alt", "iOS explanation 3");
			attr(p4, "class", "instruction svelte-1o4k9j1");
			if (img3.src !== (img3_src_value = "./gpsPermissions/android/01.jpg")) attr(img3, "src", img3_src_value);
			attr(img3, "alt", "iOS explanation 1");
			attr(img3, "class", "svelte-1o4k9j1");
			attr(p5, "class", "instruction svelte-1o4k9j1");
			attr(p6, "class", "instruction last svelte-1o4k9j1");
			attr(div1, "class", "scrollable container svelte-1o4k9j1");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			mount_component(logo, div1, null);
			append(div1, t0);
			append(div1, h20);
			append(div1, t2);
			append(div1, div0);
			append(div1, t6);
			append(div1, h21);
			append(div1, t8);
			append(div1, p1);
			append(div1, t10);
			append(div1, img0);
			append(div1, t11);
			append(div1, p2);
			append(div1, t13);
			append(div1, img1);
			append(div1, t14);
			append(div1, p3);
			append(div1, t16);
			append(div1, img2);
			append(div1, t17);
			append(div1, h22);
			append(div1, t19);
			append(div1, p4);
			append(div1, t21);
			append(div1, img3);
			append(div1, t22);
			append(div1, p5);
			append(div1, t24);
			append(div1, p6);
			/*div1_binding*/ ctx[1](div1);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(logo.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(logo.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_component(logo);
			/*div1_binding*/ ctx[1](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let container;

	onMount(() => {
		let scrollHandler = new ScrollTouchHandler(container);
		scrollHandler.addListeners();

		return () => {
			scrollHandler.stopListeners();
		};
	});

	function div1_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			container = $$value;
			$$invalidate(0, container);
		});
	}

	return [container, div1_binding];
}

class GpsPermissionsInfoPage extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1o4k9j1-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default GpsPermissionsInfoPage;