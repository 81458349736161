/* js/components/SpotView.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	binding_callbacks,
	check_outros,
	create_bidirectional_transition,
	create_component,
	create_in_transition,
	create_out_transition,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	raf,
	run_all,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import CanvasComponent from "./CanvasComponent.svelte";
import FormattedTime from "./FormattedTime.svelte";
import ProgressBar from "./ProgressBar.svelte";
import InfoView from "./InfoView.svelte";
import { fade, fly } from "svelte/transition";
import { createEventDispatcher } from "svelte";
import { onMount, onDestroy } from "svelte";
import { slideFromBelow } from "../transitions.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-k3y9bj-style";
	style.textContent = ".artist-info.svelte-k3y9bj{position:absolute;width:80vmin;left:calc((100vw - 90vmin) / 2);bottom:6rem;z-index:1601}.spotTitleSVG.svelte-k3y9bj{position:absolute;top:2rem;left:0;height:calc(100vh - 15rem);width:100vw;z-index:1601}.pink-layer.svelte-k3y9bj{position:absolute;width:100%;height:100%;z-index:1499;background-color:#9dff8244}h1.svelte-k3y9bj{padding-bottom:0rem;margin:0}h2.svelte-k3y9bj{padding-bottom:0rem;margin:0}h3.svelte-k3y9bj{margin-bottom:2rem}";
	append(document.head, style);
}

// (150:0) {#if !preStageHidden}
function create_if_block_3(ctx) {
	let div0;
	let button;
	let button_intro;
	let button_outro;
	let t1;
	let div1;
	let div1_intro;
	let div1_outro;
	let t2;
	let canvascomponent;
	let t3;
	let div2;
	let h1;
	let t4_value = /*spot*/ ctx[0].name + "";
	let t4;
	let t5;
	let h3;
	let t6_value = /*spot*/ ctx[0].artist + "";
	let t6;
	let div2_transition;
	let current;
	let mounted;
	let dispose;
	canvascomponent = new CanvasComponent({ props: { duration: 1000 } });

	return {
		c() {
			div0 = element("div");
			button = element("button");
			button.textContent = "play";
			t1 = space();
			div1 = element("div");
			t2 = space();
			create_component(canvascomponent.$$.fragment);
			t3 = space();
			div2 = element("div");
			h1 = element("h1");
			t4 = text(t4_value);
			t5 = space();
			h3 = element("h3");
			t6 = text(t6_value);
			attr(button, "class", "button");
			attr(div0, "class", "buttonWrapper");
			set_style(div0, "z-index", "3001");
			attr(div1, "class", "pink-layer svelte-k3y9bj");
			attr(h1, "class", "svelte-k3y9bj");
			attr(h3, "class", "svelte-k3y9bj");
			attr(div2, "class", "artist-info svelte-k3y9bj");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, button);
			insert(target, t1, anchor);
			insert(target, div1, anchor);
			insert(target, t2, anchor);
			mount_component(canvascomponent, target, anchor);
			insert(target, t3, anchor);
			insert(target, div2, anchor);
			append(div2, h1);
			append(h1, t4);
			append(div2, t5);
			append(div2, h3);
			append(h3, t6);
			current = true;

			if (!mounted) {
				dispose = listen(button, "click", /*onPlayIconClick*/ ctx[12]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if ((!current || dirty & /*spot*/ 1) && t4_value !== (t4_value = /*spot*/ ctx[0].name + "")) set_data(t4, t4_value);
			if ((!current || dirty & /*spot*/ 1) && t6_value !== (t6_value = /*spot*/ ctx[0].artist + "")) set_data(t6, t6_value);
		},
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (button_outro) button_outro.end(1);
				if (!button_intro) button_intro = create_in_transition(button, fade, { duration: 1000 });
				button_intro.start();
			});

			add_render_callback(() => {
				if (div1_outro) div1_outro.end(1);
				if (!div1_intro) div1_intro = create_in_transition(div1, fade, { duration: 500 });
				div1_intro.start();
			});

			transition_in(canvascomponent.$$.fragment, local);

			add_render_callback(() => {
				if (!div2_transition) div2_transition = create_bidirectional_transition(div2, fade, { duration: 1000 }, true);
				div2_transition.run(1);
			});

			current = true;
		},
		o(local) {
			if (button_intro) button_intro.invalidate();
			button_outro = create_out_transition(button, fade, { duration: 200, delay: 150 });
			if (div1_intro) div1_intro.invalidate();
			div1_outro = create_out_transition(div1, fade, { duration: 200, delay: 150 });
			transition_out(canvascomponent.$$.fragment, local);
			if (!div2_transition) div2_transition = create_bidirectional_transition(div2, fade, { duration: 1000 }, false);
			div2_transition.run(0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching && button_outro) button_outro.end();
			if (detaching) detach(t1);
			if (detaching) detach(div1);
			if (detaching && div1_outro) div1_outro.end();
			if (detaching) detach(t2);
			destroy_component(canvascomponent, detaching);
			if (detaching) detach(t3);
			if (detaching) detach(div2);
			if (detaching && div2_transition) div2_transition.end();
			mounted = false;
			dispose();
		}
	};
}

// (201:0) {#if playerVisible}
function create_if_block_1(ctx) {
	let div6;
	let div5;
	let img0;
	let img0_src_value;
	let t0;
	let div0;
	let img1;
	let img1_src_value;
	let t1;
	let div1;
	let h2;
	let t2_value = /*spot*/ ctx[0].artist + "";
	let t2;
	let t3;
	let h3;
	let t4_value = /*spot*/ ctx[0].name + "";
	let t4;
	let t5;
	let current_block_type_index;
	let if_block;
	let t6;
	let div4;
	let button0;
	let t7;
	let div2;
	let button1;
	let div3;
	let button2;
	let div6_transition;
	let current;
	let mounted;
	let dispose;
	const if_block_creators = [create_if_block_2, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*canPlay*/ ctx[4]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div6 = element("div");
			div5 = element("div");
			img0 = element("img");
			t0 = space();
			div0 = element("div");
			img1 = element("img");
			t1 = space();
			div1 = element("div");
			h2 = element("h2");
			t2 = text(t2_value);
			t3 = space();
			h3 = element("h3");
			t4 = text(t4_value);
			t5 = space();
			if_block.c();
			t6 = space();
			div4 = element("div");
			button0 = element("button");
			t7 = text(/*playButtonText*/ ctx[9]);
			div2 = element("div");
			button1 = element("button");
			button1.textContent = "info";
			div3 = element("div");
			button2 = element("button");
			button2.textContent = "close";
			attr(img0, "class", "close-button");
			if (img0.src !== (img0_src_value = "close.svg")) attr(img0, "src", img0_src_value);
			attr(img0, "alt", "close button");
			attr(img1, "class", "spotTitleSVG svelte-k3y9bj");
			if (img1.src !== (img1_src_value = `./spotTitles/${/*spot*/ ctx[0].id}.svg`)) attr(img1, "src", img1_src_value);
			attr(img1, "alt", ".");
			attr(h2, "class", "svelte-k3y9bj");
			attr(h3, "class", "svelte-k3y9bj");
			attr(div1, "class", "audioplayer");
			attr(button0, "class", "button");
			attr(div2, "class", "buttonSpacer");
			attr(button1, "class", "button");
			attr(div3, "class", "buttonSpacer");
			attr(button2, "class", "button");
			attr(div4, "class", "buttonWrapper");
			attr(div5, "class", "container");
			attr(div6, "class", "spotView");
		},
		m(target, anchor) {
			insert(target, div6, anchor);
			append(div6, div5);
			append(div5, img0);
			append(div5, t0);
			append(div5, div0);
			append(div0, img1);
			append(div5, t1);
			append(div5, div1);
			append(div1, h2);
			append(h2, t2);
			append(div1, t3);
			append(div1, h3);
			append(h3, t4);
			append(div1, t5);
			if_blocks[current_block_type_index].m(div1, null);
			append(div5, t6);
			append(div5, div4);
			append(div4, button0);
			append(button0, t7);
			append(div4, div2);
			append(div4, button1);
			append(div4, div3);
			append(div4, button2);
			current = true;

			if (!mounted) {
				dispose = [
					listen(img0, "click", /*requestCloseView*/ ctx[10]),
					listen(button0, "click", /*playPause*/ ctx[11]),
					listen(button1, "click", /*onInfoClick*/ ctx[13]),
					listen(button2, "click", /*requestCloseView*/ ctx[10])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (!current || dirty & /*spot*/ 1 && img1.src !== (img1_src_value = `./spotTitles/${/*spot*/ ctx[0].id}.svg`)) {
				attr(img1, "src", img1_src_value);
			}

			if ((!current || dirty & /*spot*/ 1) && t2_value !== (t2_value = /*spot*/ ctx[0].artist + "")) set_data(t2, t2_value);
			if ((!current || dirty & /*spot*/ 1) && t4_value !== (t4_value = /*spot*/ ctx[0].name + "")) set_data(t4, t4_value);
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				}

				transition_in(if_block, 1);
				if_block.m(div1, null);
			}

			if (!current || dirty & /*playButtonText*/ 512) set_data(t7, /*playButtonText*/ ctx[9]);
		},
		i(local) {
			if (current) return;
			transition_in(if_block);

			add_render_callback(() => {
				if (!div6_transition) div6_transition = create_bidirectional_transition(div6, slideFromBelow, { duration: 500 }, true);
				div6_transition.run(1);
			});

			current = true;
		},
		o(local) {
			transition_out(if_block);
			if (!div6_transition) div6_transition = create_bidirectional_transition(div6, slideFromBelow, { duration: 500 }, false);
			div6_transition.run(0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div6);
			if_blocks[current_block_type_index].d();
			if (detaching && div6_transition) div6_transition.end();
			mounted = false;
			run_all(dispose);
		}
	};
}

// (227:20) {:else}
function create_else_block(ctx) {
	let p;

	return {
		c() {
			p = element("p");
			p.textContent = "en cours de chargement...";
		},
		m(target, anchor) {
			insert(target, p, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (224:20) {#if canPlay}
function create_if_block_2(ctx) {
	let progressbar;
	let t;
	let formattedtime;
	let current;

	progressbar = new ProgressBar({
			props: {
				duration: /*audioDuration*/ ctx[5],
				currentTime: /*audioCurrentTime*/ ctx[6]
			}
		});

	formattedtime = new FormattedTime({
			props: { seconds: /*reverseCurrentTime*/ ctx[8] }
		});

	return {
		c() {
			create_component(progressbar.$$.fragment);
			t = space();
			create_component(formattedtime.$$.fragment);
		},
		m(target, anchor) {
			mount_component(progressbar, target, anchor);
			insert(target, t, anchor);
			mount_component(formattedtime, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const progressbar_changes = {};
			if (dirty & /*audioDuration*/ 32) progressbar_changes.duration = /*audioDuration*/ ctx[5];
			if (dirty & /*audioCurrentTime*/ 64) progressbar_changes.currentTime = /*audioCurrentTime*/ ctx[6];
			progressbar.$set(progressbar_changes);
			const formattedtime_changes = {};
			if (dirty & /*reverseCurrentTime*/ 256) formattedtime_changes.seconds = /*reverseCurrentTime*/ ctx[8];
			formattedtime.$set(formattedtime_changes);
		},
		i(local) {
			if (current) return;
			transition_in(progressbar.$$.fragment, local);
			transition_in(formattedtime.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(progressbar.$$.fragment, local);
			transition_out(formattedtime.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(progressbar, detaching);
			if (detaching) detach(t);
			destroy_component(formattedtime, detaching);
		}
	};
}

// (244:0) {#if showInfoView}
function create_if_block(ctx) {
	let infoview;
	let current;
	infoview = new InfoView({ props: { id: /*spot*/ ctx[0].id } });
	infoview.$on("closeRequest", /*onInfoViewRequestsClosing*/ ctx[14]);

	return {
		c() {
			create_component(infoview.$$.fragment);
		},
		m(target, anchor) {
			mount_component(infoview, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const infoview_changes = {};
			if (dirty & /*spot*/ 1) infoview_changes.id = /*spot*/ ctx[0].id;
			infoview.$set(infoview_changes);
		},
		i(local) {
			if (current) return;
			transition_in(infoview.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(infoview.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(infoview, detaching);
		}
	};
}

function create_fragment(ctx) {
	let audio;
	let source0;
	let source0_src_value;
	let source1;
	let source1_src_value;
	let audio_updating = false;
	let audio_animationframe;
	let t0;
	let t1;
	let t2;
	let if_block2_anchor;
	let current;
	let mounted;
	let dispose;

	function audio_timeupdate_handler() {
		cancelAnimationFrame(audio_animationframe);

		if (!audio.paused) {
			audio_animationframe = raf(audio_timeupdate_handler);
			audio_updating = true;
		}

		/*audio_timeupdate_handler*/ ctx[17].call(audio);
	}

	let if_block0 = !/*preStageHidden*/ ctx[3] && create_if_block_3(ctx);
	let if_block1 = /*playerVisible*/ ctx[2] && create_if_block_1(ctx);
	let if_block2 = /*showInfoView*/ ctx[7] && create_if_block(ctx);

	return {
		c() {
			audio = element("audio");
			source0 = element("source");
			source1 = element("source");
			t0 = space();
			if (if_block0) if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			t2 = space();
			if (if_block2) if_block2.c();
			if_block2_anchor = empty();
			if (source0.src !== (source0_src_value = `./music/${/*spot*/ ctx[0].audio.mp3}`)) attr(source0, "src", source0_src_value);
			attr(source0, "preload", "metadata");
			attr(source0, "type", "audio/mpeg; codecs=\"mp3\"");
			if (source1.src !== (source1_src_value = `./music/${/*spot*/ ctx[0].audio.ogg}`)) attr(source1, "src", source1_src_value);
			attr(source1, "preload", "metadata");
			attr(source1, "type", "audio/ogg; codecs=\"vorbis\"");
			if (/*audioDuration*/ ctx[5] === void 0) add_render_callback(() => /*audio_durationchange_handler*/ ctx[16].call(audio));
		},
		m(target, anchor) {
			insert(target, audio, anchor);
			append(audio, source0);
			append(audio, source1);
			/*audio_binding*/ ctx[15](audio);
			insert(target, t0, anchor);
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t1, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, t2, anchor);
			if (if_block2) if_block2.m(target, anchor);
			insert(target, if_block2_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = [
					listen(audio, "durationchange", /*audio_durationchange_handler*/ ctx[16]),
					listen(audio, "timeupdate", audio_timeupdate_handler)
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*spot*/ 1 && source0.src !== (source0_src_value = `./music/${/*spot*/ ctx[0].audio.mp3}`)) {
				attr(source0, "src", source0_src_value);
			}

			if (!current || dirty & /*spot*/ 1 && source1.src !== (source1_src_value = `./music/${/*spot*/ ctx[0].audio.ogg}`)) {
				attr(source1, "src", source1_src_value);
			}

			if (!audio_updating && dirty & /*audioCurrentTime*/ 64 && !isNaN(/*audioCurrentTime*/ ctx[6])) {
				audio.currentTime = /*audioCurrentTime*/ ctx[6];
			}

			audio_updating = false;

			if (!/*preStageHidden*/ ctx[3]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*preStageHidden*/ 8) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(t1.parentNode, t1);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*playerVisible*/ ctx[2]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*playerVisible*/ 4) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(t2.parentNode, t2);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*showInfoView*/ ctx[7]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);

					if (dirty & /*showInfoView*/ 128) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(if_block2_anchor.parentNode, if_block2_anchor);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(if_block2);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(if_block2);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(audio);
			/*audio_binding*/ ctx[15](null);
			if (detaching) detach(t0);
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t1);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(t2);
			if (if_block2) if_block2.d(detaching);
			if (detaching) detach(if_block2_anchor);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { spot = null } = $$props;
	const dispatch = createEventDispatcher();
	let player;
	let playing = false;
	let playerVisible = false;
	let preStageHidden = false;
	let canPlay = false; // true when file is ready to play
	let audioDuration;
	let audioCurrentTime;
	let showInfoView = false;

	function requestCloseView() {
		dispatch("close-request", {});
	}

	async function playPause() {
		if (player.paused) {
			await player.play();
			$$invalidate(18, playing = true);
		} else {
			await player.pause();
			$$invalidate(18, playing = false);
		}
	}

	function onPlayIconClick() {
		playPause();
		$$invalidate(2, playerVisible = true);
		$$invalidate(3, preStageHidden = true);
	}

	function onTrackEnded() {
		requestCloseView();
	}

	function onInfoClick() {
		$$invalidate(7, showInfoView = true);
	}

	function onInfoViewRequestsClosing() {
		$$invalidate(7, showInfoView = false);
	}

	function onCanPlayThrough() {
		$$invalidate(4, canPlay = true);
	}

	onMount(() => {
		$$invalidate(3, preStageHidden = false);
		player.addEventListener("ended", onTrackEnded);
		player.addEventListener("canplaythrough", onCanPlayThrough);

		return () => {
			player.removeEventListener("ended", onTrackEnded);
			player.removeEventListener("canplaythrough", onCanPlayThrough);
		};
	});

	function audio_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			player = $$value;
			$$invalidate(1, player);
		});
	}

	function audio_durationchange_handler() {
		audioDuration = this.duration;
		$$invalidate(5, audioDuration);
	}

	function audio_timeupdate_handler() {
		audioCurrentTime = this.currentTime;
		$$invalidate(6, audioCurrentTime);
	}

	$$self.$set = $$props => {
		if ("spot" in $$props) $$invalidate(0, spot = $$props.spot);
	};

	let reverseCurrentTime;
	let playButtonText;

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*audioDuration, audioCurrentTime*/ 96) {
			$: $$invalidate(8, reverseCurrentTime = audioDuration - audioCurrentTime);
		}

		if ($$self.$$.dirty & /*playing*/ 262144) {
			$: $$invalidate(9, playButtonText = playing ? "pause" : "play");
		}
	};

	return [
		spot,
		player,
		playerVisible,
		preStageHidden,
		canPlay,
		audioDuration,
		audioCurrentTime,
		showInfoView,
		reverseCurrentTime,
		playButtonText,
		requestCloseView,
		playPause,
		onPlayIconClick,
		onInfoClick,
		onInfoViewRequestsClosing,
		audio_binding,
		audio_durationchange_handler,
		audio_timeupdate_handler
	];
}

class SpotView extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-k3y9bj-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { spot: 0 });
	}
}

export default SpotView;