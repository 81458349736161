import { writable } from 'svelte/store';


function createIntroState(){
    const geoLocationInfoPageCompleteKey = 'geo_location_info_page_complete_key';

    let geoLocationInfoPageCompleted = false;
    try{
        geoLocationInfoPageCompleted = !!window.localStorage.getItem(geoLocationInfoPageCompleteKey);
    }catch(e){
        console.log("cookies are no go");
    }

    // console.log("geo location info page was completed", geoLocationInfoPageCompleted);

    const startState = {
        geoLocationInfoPageCompleted: geoLocationInfoPageCompleted,
        introCompleted:false, 
    }

    const { subscribe, update } = writable(startState);

    return {
        subscribe,
        clear: () => {
            // only for debug purposes
            try{
                window.localStorage.removeItem(geoLocationInfoPageCompleteKey);
            }catch(e){
                console.log("all cookies blocked");
            }
            update(s => { 
                return {
                    introCompleted: s.introCompleted, 
                    geoLocationInfoPageCompleted: false 
                }
            });
        },
        setGeoLocationInfoPageComplete: () => {
            try{
                window.localStorage.setItem(geoLocationInfoPageCompleteKey, true);
            }catch(e){
                console.log("all cookies blocked");
            }
            update(s => { 
                return {
                    introCompleted: s.introCompleted, 
                    geoLocationInfoPageCompleted: true 
                }
            });
        },
        setIntroModuleComplete: () => {
            update(s => { 
                return {
                    introCompleted: true, 
                    geoLocationInfoPageCompleted: s.geoLocationInfoPageCompleted 
                }
            });
        },
    };
}

export const introStateStore = createIntroState();