export default class SimplePhysics
{
    constructor(updateCb){
        this.updateCb = updateCb;
        this.velocity = 0;
        this.accelerationValues = [];
        this.requestFrameId = -1;
        this.friction = 0.95;
    }

    addAcceleration(acceleration){
        this.accelerationValues.push(acceleration);
        if(this.accelerationValues.length > 10){
            this.accelerationValues.shift();
        }
    }

    reset(){
        this.velocity = 0;
        this.accelerationValues = [];
    }

    invertVelocity(){
        this.velocity *= -1;
        this.velocity *= 0.08;
    }

    step(){
        this.velocity *= this.friction;

        // console.log("stepping", this.velocity);
        
        if(Math.abs(this.velocity) < 0.05){
            // all done
        }else{
            this.requestFrameId = requestAnimationFrame(this.step.bind(this));
        }

        this.updateCb(this.velocity);
    }

    start(){
        if(this.accelerationValues.length == 0){
            this.velocity = 0;
        }else{
            this.velocity = this.accelerationValues.reduce((sum, current) => sum + current, 0) / this.accelerationValues.length;
        }
        this.requestFrameId = requestAnimationFrame(this.step.bind(this));

        // console.log("start physix, velocity:", this.velocity);
    }

    stop(){
        cancelAnimationFrame(this.requestFrameId);
        this.reset();
    }
}