/* js/components/info/InfoPage.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let h1;
	let t1;
	let h20;
	let t4;
	let br1;
	let br2;
	let t5;
	let div0;
	let t15;
	let br11;
	let br12;
	let br13;
	let t16;
	let h21;
	let t18;
	let p0;
	let t20;
	let div1;
	let t25;
	let p1;
	let t27;
	let p2;
	let t47;
	let p3;
	let t49;
	let div2;
	let t54;
	let p4;
	let t55;
	let div3;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "AudioGenese";
			t1 = space();
			h20 = element("h2");
			h20.innerHTML = `Genève<br>15.8-15.9.2020`;
			t4 = space();
			br1 = element("br");
			br2 = element("br");
			t5 = space();
			div0 = element("div");

			div0.innerHTML = `
        AudioGenese est un projet de scènes virtuelles. Des propositions musicales et sonores vous attendent, disséminées dans des lieux particuliers. Composés par une équipe de musiciens hors pair, ces moments d&#39;écoute vous feront vivre chaque lieu d&#39;une manière personnelle, à travers le regard de 8 artistes sonores.
        <br>  
        <br> 
        L&#39;application que vous avez entre les mains vous guidera vers ces lieux. Veuillez s&#39;il vous-plaît confirmer l&#39;activation du GPS, c&#39;est la technologie que nous utilisons. Il n&#39;y a pas de traçage, les données ne quittent pas votre appareil mobile.
        <br>  
        <br> 
        Ce projet est en partie inspiré par le travail de Peter Greenaway, qui proposait d’observer le paysage à travers son œil dans ses installations «Stairs».
        <br>  
        <br> 
        N&#39;oubliez pas de prendre un casque audio avec vous pour profiter pleinement de l&#39;expérience. Bonne écoute à tous!
        <br>  
        <br> 
        [D. Delcourt / D. Hodgetts, août 2020]
        

    `;

			t15 = space();
			br11 = element("br");
			br12 = element("br");
			br13 = element("br");
			t16 = space();
			h21 = element("h2");
			h21.textContent = "Remerciements";
			t18 = space();
			p0 = element("p");
			p0.textContent = "Le teem";
			t20 = space();
			div1 = element("div");

			div1.innerHTML = `
        Mika Ventura<br>
        Shiwa Tseng<br>
        Philippe Richard<br>
        Sophie Czich<br>`;

			t25 = space();
			p1 = element("p");
			p1.textContent = "Les institutions et lieux";
			t27 = space();
			p2 = element("p");

			p2.innerHTML = `
        Véronique Lombard — Ville de Genève, DCTN<br>
        Eve-Anouk Gruosso — Ville de Genève, DCTN<br>
        Patrizia Gruosso — Ville de Genève, SEP<br>
        Thierry Lin — Canton de Genève, DI<br>
        Christelle Metthez Ville de Genève, DSES<br><br>
        
        Gilles Taramarcaz —  Parc la Grange, SEVE<br>
        Boris Briffaud —  Parc la Grange, SEVE<br><br>
        
        Mme Girardet — Commune de Veyrier, <br>Service de la culture<br>
        Cédric Domenigoni, Commune de Veyrier, <br>police municipale<br><br>
        
        
        Catherine Jeandet — Commune <br>de Pregny-Chambésy<br>
        Geoffrey Aloisy — Chateau de Penthes<br><br>
        
        Michael Gussing — Ville de Genève, SPF<br>
        Catherine Gauthier — Cimetière St-Georges<br>
        Serge Viennaz — Cimetière des Rois<br><br>

        Philippe Constantin — Bains des Pâquis, AUBP<br>
        Andrea Novicov — Théâtre de l’Orangerie<br>`;

			t47 = space();
			p3 = element("p");
			p3.textContent = "Presse et partenaires";
			t49 = space();
			div2 = element("div");

			div2.innerHTML = `
        AMR — François Tschumy<br>
        Cave 12 — Fernando Sixto<br>
        Radio Vostok — Charles Menger<br>
        RTS Culture — Miruna Coca Cosma<br>`;

			t54 = space();
			p4 = element("p");
			p4.innerHTML = `<br><br><br><br><br><br>`;
			t55 = space();
			div3 = element("div");
			div3.innerHTML = `<a href="mailto:contact@audiogenese.net"><button class="button">Contact</button></a>`;
			attr(h1, "class", "indent");
			attr(div0, "class", "text-indent");
			attr(p0, "class", "interview_question");
			attr(div1, "class", "text-indent");
			attr(p1, "class", "interview_question");
			attr(p3, "class", "interview_question");
			attr(div2, "class", "text-indent");
			attr(div3, "class", "buttonWrapper");
			set_style(div3, "z-index", "3001");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, h20, anchor);
			insert(target, t4, anchor);
			insert(target, br1, anchor);
			insert(target, br2, anchor);
			insert(target, t5, anchor);
			insert(target, div0, anchor);
			insert(target, t15, anchor);
			insert(target, br11, anchor);
			insert(target, br12, anchor);
			insert(target, br13, anchor);
			insert(target, t16, anchor);
			insert(target, h21, anchor);
			insert(target, t18, anchor);
			insert(target, p0, anchor);
			insert(target, t20, anchor);
			insert(target, div1, anchor);
			insert(target, t25, anchor);
			insert(target, p1, anchor);
			insert(target, t27, anchor);
			insert(target, p2, anchor);
			insert(target, t47, anchor);
			insert(target, p3, anchor);
			insert(target, t49, anchor);
			insert(target, div2, anchor);
			insert(target, t54, anchor);
			insert(target, p4, anchor);
			insert(target, t55, anchor);
			insert(target, div3, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(h20);
			if (detaching) detach(t4);
			if (detaching) detach(br1);
			if (detaching) detach(br2);
			if (detaching) detach(t5);
			if (detaching) detach(div0);
			if (detaching) detach(t15);
			if (detaching) detach(br11);
			if (detaching) detach(br12);
			if (detaching) detach(br13);
			if (detaching) detach(t16);
			if (detaching) detach(h21);
			if (detaching) detach(t18);
			if (detaching) detach(p0);
			if (detaching) detach(t20);
			if (detaching) detach(div1);
			if (detaching) detach(t25);
			if (detaching) detach(p1);
			if (detaching) detach(t27);
			if (detaching) detach(p2);
			if (detaching) detach(t47);
			if (detaching) detach(p3);
			if (detaching) detach(t49);
			if (detaching) detach(div2);
			if (detaching) detach(t54);
			if (detaching) detach(p4);
			if (detaching) detach(t55);
			if (detaching) detach(div3);
		}
	};
}

class InfoPage extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default InfoPage;