import { readable } from 'svelte/store';
import GeoLocationProvider from './geoLocationProvider';
import { fakeGeoLocationProvider } from './fakeGeoLocationProvider';

import { notificationStore } from './notificationStore';
import { genevaCoords } from './geoUtils';

import LocationTween from './locationTween';

import { GeoLocationMessage } from './geoLocation';

import { Config } from './config';

let startState = {
    error:null,
    coords:null, 
};

// set to true for debug session
// set to false to use native gps

export const geoLocationProvider = readable(startState, function start(set) {
    const provider = Config.useFakeGpsProvider ? fakeGeoLocationProvider :  new GeoLocationProvider();

    if(Config.useFakeGpsProvider){
        const message = 'warning using fake gps provider';
        notificationStore.publishSuccess(message);
    }

    let tween = null;
    let fromCoords = genevaCoords.slice();
    let currentCoords = genevaCoords.slice();

    if(Config.useFakeGpsProvider){
        setTimeout(() => provider.publishCoords(genevaCoords), 1500);
    }

    function onNewCoords(coordsMessage){
        if(coordsMessage.error){
            set(coordsMessage);
            return;
        }

        if(tween && tween.isTweening){
            tween.stop();
            fromCoords = currentCoords.slice();
        }

        const tweenStep = (nextCoords) => {
            set(GeoLocationMessage.newCoords(nextCoords.slice()));
            currentCoords = nextCoords.slice();
        }

        const tweenComplete = (nextCoords) => {
            set(GeoLocationMessage.newCoords(nextCoords.slice()));
            fromCoords = nextCoords.slice();
        }

        const tweenDuration = Config.useFakeGpsProvider ? 4000 : 1000; // ms

        tween = new LocationTween(fromCoords, coordsMessage.coords, tweenDuration, tweenStep, tweenComplete);
        tween.start();
    }

    provider.addListener(onNewCoords);
    provider.start();

    return function stop(){
        provider.removeListener(onNewCoords);
        provider.stop();
    }
});