
function createMapEventProvider(){
    let listeners = [];

    let map = null;

    function _broadcastCoords (coords) {
        listeners.forEach( l => l(coords));
    }

    let result = {
        addListener: (listener) => listeners.push(listener),
        removeListener: (listener) => {
            const index = listeners.indexOf(listener);
            if(index >= 0){
                listeners.splice(index, 1);
            }
        },
        initMap:(aMap) => {
            if(map == aMap) return;
            map = aMap;
            map.on('click', function(e){
                // console.log("click", e);
                _broadcastCoords({
                    type:'click',
                    screenCoords:e.point
                });
            });
            map.on('touchstart', function(e){
                // console.log("touchstart", e);
                _broadcastCoords({
                    type:'touchstart',
                    screenCoords:e.point
                });
            });
            map.on('touchmove', function(e){
                // console.log("touchstart", e);
                _broadcastCoords({
                    type:'touchmove',
                    screenCoords:e.point
                });
            });
            map.on('mousemove', function(e){
                // console.log("touchstart", e);
                _broadcastCoords({
                    type:'mousemove',
                    screenCoords:e.point
                });
            });
            map.on('mousedown', function(e){
                // console.log("mousedown", e);
                _broadcastCoords({
                    type:'mousedown',
                    screenCoords:e.point
                });
            });
            console.log("inited map click provider");
        }
    }

    return result;
}

export const mapEventProvider = createMapEventProvider();