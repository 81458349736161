
export const NotificationType = {
    SUCCESS:'success',
    WARNING:'warning',
    ERROR:'error',
};

let id = 0;

export class Notification
{
    constructor(type, message){
        this.id = id++;
        this.type = type;
        this.message = message;
    }
}

function createStore(){
    let listeners = [];

    return {
        addListener: (listener) => listeners.push(listener),
        removeListener: (listener) => {
            const index = listeners.indexOf(listener);
            if(index >= 0){
                listeners.splice(index, 1);
            }
        },
        publishSuccess: (message) => {
            listeners.forEach( l => l(new Notification(NotificationType.SUCCESS, message)));
        },
        publishWarning: (message) => {
            listeners.forEach( l => l(new Notification(NotificationType.WARNING, message)));
        },
        publishError: (message) => {
            listeners.forEach( l => l(new Notification(NotificationType.ERROR, message)));
        },
    }
}

export const notificationStore = createStore();