/* js/components/WaitSpinner.svelte generated by Svelte v3.23.2 */
import {
	SvelteComponent,
	add_render_callback,
	attr,
	create_bidirectional_transition,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

import { fade } from "svelte/transition";

function create_fragment(ctx) {
	let div;
	let div_transition;
	let current;

	return {
		c() {
			div = element("div");
			div.innerHTML = `<p>please wait...</p>`;
			attr(div, "class", "container");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (!div_transition) div_transition = create_bidirectional_transition(div, fade, {}, true);
				div_transition.run(1);
			});

			current = true;
		},
		o(local) {
			if (!div_transition) div_transition = create_bidirectional_transition(div, fade, {}, false);
			div_transition.run(0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (detaching && div_transition) div_transition.end();
		}
	};
}

class WaitSpinner extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default WaitSpinner;